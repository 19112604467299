<script>
export default {
    name: 'settings-form',

    props: [
        'settingsEndpoint',
        'updateEndpoint'
    ],

    data() {
        return {
            id: '',
            first_name: '',
            surname: '',
            email: '',
            phone: '',
            new_password: null,
            new_password_confirmation: null,
            avatar: null,
            successMessage: false,
            errors: []
        }
    },

    mounted() {
        this.fetchUserSettings();
    },

    methods: {
        fetchUserSettings() {
            axios.get(this.settingsEndpoint)
                .then(response => {
                    this.id = response.data.id;
                    this.first_name = response.data.first_name;
                    this.surname = response.data.surname;
                    this.email = response.data.email;
                    this.phone = response.data.phone;
                });
        },

        uploadImage(e) {
            this.errors.avatar = '';
            const image = e.target.files[0];
            const reader = new FileReader();
            const fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

            const extension = e.target.files[0].name.split('.').pop().toLowerCase(),  //file extension from input file
            isSuccess = fileTypes.indexOf(extension) > -1;

            if (isSuccess) {
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.avatar = e.target.result;
                };
            } else {
                this.errors.avatar = ['.' + extension + ' is a wrong filetype!'];

                this.avatar = '';

                this.$forceUpdate();
            }
        },

        updateAccount() {
            this.errors = [];

            axios.patch(this.updateEndpoint, {
                'id': this.id,
                'first_name': this.first_name,
                'surname': this.surname,
                'email': this.email,
                'phone': this.phone,
                'avatar': this.avatar,
                'new_password': this.new_password,
                'new_password_confirmation': this.new_password_confirmation,
            }).then(response => {
                if (response.data.errors) {
                    this.errors = response.data.errors;
                } else {
                    this.successMessage = true;

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
        }
    }
}
</script>
