<script>
import {DataTable} from 'laravel-vue-datatable';
import ActionDataTableButtons from "./ActionDataTableButtons";

export default {
    name: 'coefficients-table',

    props: [
        'coefficientsEndpoint',
        'updateCoefficientsEndpoint',
    ],

    components: { DataTable, ActionDataTableButtons },

    data() {
        return {
            columns: [
                {
                    name: "display_name",
                    columnName: "name",
                    label: this.$gettext('Name')
                },
                {
                    name: "value",
                    label: this.$gettext('Value'),
                    orderable: true,
                },
                {
                    meta: {0: 'coefficient'},
                    label: this.$gettext('Actions'),
                    component: ActionDataTableButtons,
                    event: 'click',
                    handler: this.getRowData
                }
            ],
            perPage: ['10', '25', '50', '100'],
            tableProps: {
                search: '',
                length: 10,
                column: 'id',
                dir: 'asc',
            },
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous'),
                placeholderSearch: this.$gettext('Search coefficient'),
            },
            errors: [],
            rowData: {},
            editData: {}
        }
    },

    methods: {
        update() {
            this.errors = [];

            axios.post(this.updateCoefficientsEndpoint, this.editData).then(res => {
                if (res.status == 200) {
                    $("#itemModalEdit").modal('hide');

                    window.location.reload();
                }
            })
        },

        getRowData(actionName, data, meta) {
            this.errors = [];

            this.rowData = Object.assign({}, data);
            this.editData = Object.assign({}, data);
        }
    }
}
</script>
