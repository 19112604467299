<template>
    <p v-text="$gettext(capitalize(data[name][0].name.replaceAll('-', ' ')))"></p>
</template>

<script>
    export default {
        props: {
            data: {},
            name: {},
        },

        methods: {
            // Capitalize string
            capitalize(sentence) {
                return sentence.charAt(0).toUpperCase() + sentence.slice(1);
            }
        }
    }
</script>
