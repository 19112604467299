<template>
    <div class="action-buttons">
        <button class="btn color-henco-blue border-henco-blue btn-view shadow-sm mr-2 p-0"
                @click="click('view', data, meta)" data-toggle="modal" data-target="#itemModal">
            <i class="fa fa-eye"></i>
        </button>
        <button v-if="($hasRole('henco-floor-super-user') ||
                $hasRole('henco-floor-internal-user'))"
                class="btn text-success border-success btn-edit shadow-sm mr-2 p-0"
                @click="click('edit', data, meta)" data-toggle="modal" data-target="#itemModalEdit">
            <i class="fa fa-edit"></i>
        </button>
        <button v-if="($hasRole('henco-floor-super-user') ||
                $hasRole('henco-floor-internal-user')) && meta[0] !== 'coefficient' && meta[0] !== 'services'"
                class="btn color-henco-red border-henco-red btn-delete shadow-sm p-0"
                @click="click('delete', data, meta)" data-toggle="modal" data-target="#itemModalDelete">
            <i class="fa fa-trash"></i>
        </button>
    </div>
</template>

<script>
    import Roles from '../mixins/roles';

    export default {
        name: "action-buttons",

        mixins: [
            Roles
        ],

        props: {
            data: {
                type: Object,
                required: true,
            },
            meta: {
                type: Object,
                required: true,
            },
            click: {
                type: Function,
                default: () => {}
            }
        },
    };
</script>

<style>
    .btn-view,
    .btn-edit,
    .btn-clone,
    .btn-delete {
        width: 40px;
        padding: 4px 8px !important;
        height: 40px;
    }

    #files .btn-view,
    #files .btn-edit,
    #files .btn-clone,
    #files .btn-delete {
        width: 40px !important;
    }

    .btn-view:hover {
        background-color: #003a78;
        color: #fff;
    }

    .btn-edit:hover {
        background-color: #38c172;
        color: #fff !important;
    }

    .btn-clone:hover {
        background-color: #ffed4a;
        color: #fff !important;
    }

    .btn-delete:hover {
        background-color: #e42313;
        color: #fff !important;
    }
</style>
