<script>
    import {DataTable} from 'laravel-vue-datatable';
    import ActionDataTableButtons from "./ActionDataTableButtons";

    export default {
        name: 'cooling-table',

        props: [
            'heatSourceEndpoint',
            'floorFinishEndpoint',
            'storeHeatSourceEndpoint',
            'storeFloorFinishEndpoint',
            'destroyTechDataEndpoint',
            'updateSystemsEndpoint'
        ],

        components: { DataTable, ActionDataTableButtons },

        data() {
            return {
                heatSource: {
                    name: '',
                    display_name: '',
                    cooling: '',
                    heating_temp_options: '',
                    heating_temp_options_custom: false,
                    cooling_temp_options: '',
                    cooling_temp_options_custom: false
                },
                floorFinish: {
                    name: '',
                    display_name: '',
                    value: ''
                },
                columns_heat_source: [
                    {
                        name: "name_formatted",
                        columnName: "display_name",
                        label: this.$gettext('Name')
                    },
                    {
                        name: "cooling_text",
                        label: this.$gettext('Cooling')
                    },
                    {
                        name: "heating_temp_options_formatted_table",
                        label: this.$gettext('Heating options')
                    },
                    {
                        name: "cooling_temp_options_formatted_table",
                        label: this.$gettext('Cooling options')
                    },
                    {
                        meta: {0: 'heat_source'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                columns_floor_finish: [
                    {
                        name: "name_formatted",
                        columnName: "display_name",
                        label: this.$gettext('Name'),
                        orderable: true
                    },
                    {
                        name: "values",
                        label: this.$gettext('R-value'),
                        orderable: true
                    },
                    {
                        meta: {0: 'floor_finish'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search technical data'),
                },
                rowData: {},
                errors: [],
                editData: {
                    id: '',
                    name: '',
                    display_name: '',
                    value: '',
                    cooling: '',
                    heating_temp_options: '',
                    heating_temp_options_custom: false,
                    cooling_temp_options: '',
                    cooling_temp_options_custom: false,
                    type: ''
                }
            }
        },

        methods: {
            addHeatSource() {
                this.errors = [];

                axios.post(this.storeHeatSourceEndpoint, {
                    'name': this.heatSource.name,
                    'cooling': this.heatSource.cooling,
                    'heating_temp_options': this.heatSource.heating_temp_options,
                    'heating_temp_options_custom': this.heatSource.heating_temp_options_custom,
                    'cooling_temp_options': this.heatSource.cooling_temp_options,
                    'cooling_temp_options_custom': this.heatSource.cooling_temp_options_custom,
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addHeatSource").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            addFloorFinish() {
                this.errors = [];

                axios.post(this.storeFloorFinishEndpoint, {
                    'name': this.floorFinish.name,
                    'value': this.floorFinish.value
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addFloorFinish").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            editItem() {
                this.errors = [];

                axios.post(this.updateSystemsEndpoint, {
                    'data': this.editData
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#itemModalEdit").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            deleteItem(id, type) {
                axios.post(this.destroyTechDataEndpoint, {
                    id, type
                }).then(response => {
                    if (response.status == 200) {
                        $("#itemModalDelete").modal('hide');

                        window.location.reload();
                    }
                })
            },

            getRowData(actionName, data, meta) {
                this.errors = [];

                this.rowData = Object.assign({}, data);
                this.rowData.type = meta[0];

                this.editData = {
                    'id': data.id,
                    'name': data.name,
                    'display_name': data.display_name,
                    'value': data.values ?? '',
                    'cooling': data.cooling ?? '',
                    'heating_temp_options': data.heating_temp_options ? data.heating_temp_options_formatted.replace('[', '').replace(']', '').replace(',custom_input', '').replace('custom_input', '') : '',
                    'heating_temp_options_custom': !!(data.heating_temp_options && data.heating_temp_options_formatted_table.includes('Custom input')),
                    'cooling_temp_options': data.cooling_temp_options ? data.cooling_temp_options_formatted.replace('[', '').replace(']', '').replace(',custom_input', '').replace('custom_input', '') : '',
                    'cooling_temp_options_custom': !!(data.cooling_temp_options && data.cooling_temp_options_formatted_table.includes('Custom input')),
                    'type': meta[0]
                };
            }
        }
    }
</script>
