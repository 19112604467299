<template>
    <vue-editor useCustomImageHandler @input="getText" :id="id" v-model="content"
                :editor-toolbar="customToolbar" :style="`height: ${height}px;`"/>
</template>
<script>
    import { eventBus } from '../app';
    import { VueEditor } from "vue2-editor";

    export default {
        name: 'editor',

        components: {
            VueEditor,
        },

        props: [
            'label',
            'id',
            'height',
        ],

        data: () => ({
            content: null,
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline"],
                [{ color: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["clean"]
            ],
        }),

        mounted() {
            eventBus.$on('updateText', data => {
                this.content = data;
            });
        },

        methods: {
            getText() {
                eventBus.$emit('getText', this.content)
            },
        }
    };
</script>

<style>
    .quillWrapper .ql-snow.ql-toolbar {
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
    }

    .ql-container.ql-snow {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    .ql-container.ql-snow .ql-editor {
        background: #fff;
    }
</style>
