<script>
import {DataTable} from 'laravel-vue-datatable';
import RolesButtons from "./RolesButtons";
import ButtonImpersonateUser from "./ButtonImpersonateUser";
import ActionDataTableButtons from "./ActionDataTableButtons";
import AssetMixin from "../mixins/assets";

export default {
    name: 'roles-table',

    mixins: [AssetMixin],

    props: [
        'usersEndpoint',
        'updateStatusEndpoint',
        'dashboardEndpoint',
        'impersonateEndpoint',
        'rolesEndpoint',
        'storeUserEndpoint',
        'updateUserEndpoint',
        'destroyUserEndpoint',
        'allCompaniesEndpoint'
    ],

    components: { DataTable, RolesButtons, ButtonImpersonateUser, ActionDataTableButtons },

    data() {
        return {
            users: {},
            columns: [
                {
                    name: "first_name",
                    label: this.$gettext('First name'),
                    orderable: true
                },
                {
                    name: "surname",
                    label: this.$gettext('Surname'),
                    orderable: true
                },
                {
                    name: "email",
                    label: this.$gettext('E-mail'),
                    orderable: true
                },
                {
                    name: "phone",
                    label: this.$gettext('Phone number'),
                    orderable: true
                },
                {
                    name: 'roles',
                    label: this.$gettext('Role'),
                    columnName: 'roles.name',
                    component: RolesButtons,
                    orderable: true,
                },
                {
                    meta: {0: 'users'},
                    label: this.$gettext('Actions'),
                    component: ActionDataTableButtons,
                    event: 'click',
                    handler: this.getRowData
                },
                {
                    name: 'impersonate',
                    meta: {0: 'login-as'},
                    label: this.$gettext('Login as'),
                    orderable: false,
                    component: ButtonImpersonateUser,
                    event: 'click',
                    handler: this.impersonateUser,
                },
            ],
            perPage: ['10', '25', '50', '100'],
            tableProps: {
                search: '',
                length: 10,
                column: 'id',
                dir: 'asc'
            },
            filters: {
                role: ''
            },
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous')
            },
            rowData: {},
            roles: {},
            modalLoader: true,
            wholesalers: [],
            wholesaler: {},
            installers: [],
            installer: {},
            executionPartners: [],
            executionPartner: {},
            newUser: {
                first_name: '',
                surname: '',
                email: '',
                phone: '',
                password: '',
                role: '',
                company: '',
            },
            errors: [],
            user: {
                id: '',
                role: '',
                company: '',
            },
            isWorker: false,
        }
    },

    mounted() {
        this.getRoles();
    },

    methods: {
        getRowData(type, data) {
            this.rowData = Object.assign({}, data);

            if (type === 'edit') {
                this.modalLoader = true;
                this.wholesaler = {};
                this.installer = {};
                this.executionPartner = {};

                this.user = {
                    id: data.id,
                    first_name: data.first_name,
                    surname: data.surname,
                    email: data.email,
                    phone: data.phone,
                    password: null,
                    role: data.roles[0].name,
                    company: data.model_id,
                };

                this.checkRole(data.roles[0].name, true);

                this.modalLoader = false;
            }

            if (type === 'view') {
                const role = this.rowData.roles[0].name;

                if (['contractor-installer', 'wholesaler', 'execution-partner'].includes(role)) {
                    axios.get(this.allCompaniesEndpoint, {
                        params: {
                            role: role
                        }
                    }).then(response => {
                        if (role === 'contractor-installer') {
                            this.installers = response.data;

                            this.installer = this.installers.filter(el => this.rowData.model_id === el.id)[0];
                        } else if (role === 'wholesaler') {
                            this.wholesalers = response.data;

                            this.wholesaler = this.wholesalers.filter(el => this.rowData.model_id === el.id)[0];
                        } else {
                            this.executionPartners = response.data;

                            this.executionPartner = this.executionPartners.filter(el => this.rowData.model_id === el.id)[0];
                        }
                    })
                }
            }
        },

        updateUserRole(data, role) {
            const selected_role = this.roles.filter(el => el.name === role)[0]

            axios.post(this.updateStatusEndpoint, {
                id: data.id,
                role_id: selected_role.id
            }).then(() => {
                alert(this.$gettext('Success'));
            })
        },

        // Capitalize string
        capitalize(sentence) {
            return sentence.charAt(0).toUpperCase() + sentence.slice(1);
        },

        getRoles() {
            axios.get(this.rolesEndpoint).then(response => {
                this.roles = response.data;
            })
        },

        impersonateUser(data) {
            axios.post(this.impersonateEndpoint, {
                'id': data.id
            }).then(() => {
                window.location = this.dashboardEndpoint;
            });
        },

        store(role) {
            let company = null;
            this.newUser.company = null;
            this.errors = [];

            if (this.isWorker) {
                company = this.checkForCompany(role);

                if (company) {
                    this.newUser.company = company.id;
                } else {
                    return;
                }
            }

            axios.post(this.storeUserEndpoint, this.newUser).then(res => {
                if (res.data.errors) {
                    this.errors = res.data.errors;
                } else {
                    if (res.status == 200) {
                        $("#addUser").modal('hide');

                        window.location.reload();
                    }
                }
            }).catch(error => {

            });
        },

        update(role) {
            let company = null;
            this.user.company = null;
            this.errors = [];

            if (this.isWorker) {
                company = this.checkForCompany(role);

                if (company) {
                    this.user.company = company.id;
                } else {
                    return;
                }
            }

            axios.post(this.updateUserEndpoint, this.user).then(res => {
                if (res.data.errors) {
                    this.errors = res.data.errors;
                } else {
                    if (res.status == 200) {
                        $("#itemModalEdit").modal('hide');

                        window.location.reload();
                    }
                }
            })
        },

        destroy() {
            axios.post(this.destroyUserEndpoint, {
                id: this.rowData.id,
            }).then(res => {
                if (res.status == 200) {
                    $("#itemModalEdit").modal('hide');

                    window.location.reload();
                }
            })
        },

        checkRole(role, rowData = false) {
            this.isWorker = false;

            if (['contractor-installer', 'wholesaler', 'execution-partner'].includes(role)) {
                this.isWorker = true;

                this.allCompanies(role, rowData);
            }
        },

        checkForCompany(role) {
            let company = null;

            switch(role) {
                case 'contractor-installer':
                    company = this.installer;

                    if (!company) {
                        this.errors.installer = [this.$gettext('Please select installer!')];

                        return;
                    }
                    break;
                case 'wholesaler':
                    company = this.wholesaler;

                    if (!company) {
                        this.errors.wholesaler = [this.$gettext('Please select wholesaler!')];

                        return;
                    }
                    break;
                case 'execution-partner':
                    company = this.executionPartner;

                    if (!company) {
                        this.errors.executionPartner = [this.$gettext('Please select execution partner!')];

                        return;
                    }
                    break;
            }

            return company;
        },

        allCompanies(role, rowData = false) {
            this.installers = null;
            this.installer = null;

            this.wholesalers = null;
            this.wholesaler = null;

            this.executionPartners = null;
            this.executionPartner = null;

            if (this.isWorker) {
                this.modalLoader = true;

                axios.get(this.allCompaniesEndpoint,{
                    params: {
                        role: role
                    }
                }).then(response => {
                    if (role === 'contractor-installer') {
                        this.installers = response.data;

                        if (rowData) this.installer = this.installers.filter(el => this.rowData.model_id === el.id)[0];
                    } else if (role === 'wholesaler') {
                        this.wholesalers = response.data;

                        if (rowData) this.wholesaler = this.wholesalers.filter(el => this.rowData.model_id === el.id)[0];
                    } else {
                        this.executionPartners = response.data;

                        if (rowData) this.executionPartner = this.executionPartners.filter(el => this.rowData.model_id === el.id)[0];
                    }

                    this.modalLoader = false;
                })
            }
        },
    }
}
</script>
