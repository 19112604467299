<script>
    import { validationMixin } from 'vuelidate';
    import { minLength, maxLength, between, email } from 'vuelidate/lib/validators';
    import AssetMixin from "../mixins/assets";
    import RolesMixin from "../mixins/roles";
    import moment from 'moment';
    import _ from "lodash";

    export default {
        name: 'selection-tool',

        mixins: [
            validationMixin,
            AssetMixin,
            RolesMixin,
        ],

        props: [
            'length',
            'projectType',
            'mainEndpoint',
            'storeEndpoint',
            'wholesalersEndpoint',
            'installersEndpoint',
            'systemsEndpoint',
            'manifoldsEndpoint',
            'tubesEndpoint',
            'techDataEndpoint',
            'screedsEndpoint',
            'userRoleEndpoint',
            'zoneControlEndpoint',
            'priUploadEndpoint',
            'groupsEndpoint',
            'itemsByGroupEndpoint',
            'addItemToListEndpoint',
            'saveMaterialListEndpoint',
            'projectNumberEndpoint',
            'checkProjectNumberEndpoint',
            'servicesEndpoint'
        ],

        data() {
            return {
                floorsNum: 1,
                wholesalers: [],
                wholesaler: {},
                installers: [],
                installer: {},
                itemGroups: [],
                itemParams: {
                    itemGroup: '',
                    itemFloor: 1,
                },
                listOfItemsByGroup: {},
                selectedNewItem: {
                    item: {},
                    qty: 1,
                    floor_id: 1
                },
                applicationType: {
                    'offer': {
                        name: 'offer',
                        displayName: this.$gettext('Offer')
                    },
                    'laying_plan': {
                        name: 'laying_plan',
                        displayName: this.$gettext('Laying plan + List of materials')
                    },
                },
                wireType: {
                    'n/a': {
                        name: 'n/a',
                        displayName: this.$gettext('N/A')
                    },
                    'wireless': {
                        name: 'wireless',
                        displayName: this.$gettext('Wireless (RF)')
                    },
                    'wired': {
                        name: 'wired',
                        displayName: this.$gettext('Wired')
                    },
                    'simple_engine': {
                        name: 'simple_engine',
                        displayName: this.$gettext('Simple engine')
                    },
                },
                heating: {
                    '24v_heating': {
                        name: '24v_heating',
                        displayName: this.$gettext('24V Heating')
                    },
                    '230v_heating': {
                        name: '230v_heating',
                        displayName: this.$gettext('230V Heating')
                    },
                    'heating_and_cooling': {
                        name: 'heating_and_cooling',
                        displayName: this.$gettext('Heating and cooling')
                    },
                    'heating_and_cooling_switchover': {
                        name: 'heating_and_cooling_switchover',
                        displayName: this.$gettext('Heating and cooling switchover via thermostat')
                    },
                },
                pipeCourse: {
                    'underfloor_heating': {
                        name: 'underfloor_heating',
                        displayName: this.$gettext('Underfloor heating under kitchen island')
                    },
                    'no_walls': {
                        name: 'no_walls',
                        displayName: this.$gettext('Not through walls')
                    },
                },
                calculationCapacity: {
                    'max_capacity': {
                        name: 'max_capacity',
                        displayName: this.$gettext('Maximum capacity')
                    },
                    'heat_loss': {
                        name: 'heat_loss',
                        displayName: this.$gettext('Heat loss calculation')
                    },
                },
                ventilationType: {
                    'type_c': {
                        name: 'type_c',
                        displayName: this.$gettext('Ventilation type C')
                    },
                    'type_d': {
                        name: 'type_d',
                        displayName: this.$gettext('Ventilation type D')
                    },
                    'none_renovation': {
                        name: 'none_renovation',
                        displayName: this.$gettext('None / renovation')
                    },
                },
                systems: {},
                manifolds: {},
                tubes: {},
                screeds: {},
                heatSources: {},
                floorFinish: {},
                floorItems: {},
                itemsSum: {},
                itemsQty: {},
                grandTotal: 0,
                grandTotalItems: 0,
                itemsQuantityTotal: 0,
                itemsFloorAreaTotal: 0,
                itemsServices: 0,
                zoneControlItems: {},
                itemsZone: 0,
                itemsZoneQty: 0,
                tenant: '',
                stepOne: {
                    applicationTypeOption: '',
                    includingInstallation: '',
                    project: {
                        name: '',
                        address: '',
                        addressTwo: '',
                        postcode: '',
                        city: '',
                        number: '',
                        executionDate: '',
                    },
                    installer: {
                        name: '',
                        address: '',
                        addressTwo: '',
                        postcode: '',
                        city: '',
                        userIndex: 0,
                        contact: '',
                        phone: '',
                        mail: '',
                    },
                    wholesale: {
                        name: '',
                        address: '',
                        addressTwo: '',
                        postcode: '',
                        city: '',
                        userIndex: 0,
                        contact: '',
                        phone: '',
                        mail: '',
                    },
                },
                stepTwo: {
                    floor1: {
                        cooling: '',
                        coolingTemperatureOptions: '',
                        coolingTemperature: '',
                        coolingTemperatureMin: '',
                        coolingTemperatureMax: '',
                        heatSource: '',
                        heatTemperature: '',
                        heatTemperatureOptions: '',
                        heatTemperatureMin: '',
                        heatTemperatureMax: '',
                        floorFinish: '',
                        floorFinishCustom: '',
                        floorArea: ''
                    }
                },
                stepThree: {
                    floor1: {
                        main: {
                            system: '',
                            subSystems: '',
                            subSystem: '',
                            diameters: '',
                            tubeSpecification: ''
                        },
                        additional: {
                            system: '',
                            subSystems: '',
                            subSystem: '',
                            diameters: '',
                            tubeSpecification: ''
                        },
                        additionalSystem: false,
                    }
                },
                stepFour: {
                    floor1: {
                        main: {
                            insulations: '',
                            insulation: '',
                            manifold: '',
                            manifoldLocation: '',
                            manifoldLocationCustom: '',
                            tubes: '',
                            tube: '',
                            tubeDiameters: '',
                            tubeDiameter: '',
                            displacementDistance: '',
                            bathroom: false,
                            bathroomArea: '',
                            screed: '',
                            screedValue: '',
                        },
                        additional: {
                            insulations: '',
                            insulation: '',
                            manifold: '',
                            manifoldLocation: '',
                            manifoldLocationCustom: '',
                            tubes: '',
                            tube: '',
                            tubeDiameters: '',
                            tubeDiameter: '',
                            displacementDistance: '',
                            screed: '',
                            screedValue: '',
                        }
                    }
                },
                stepFourAdditional: {
                    maximumGroupLength: '',
                    longPipe: '',
                },
                stepFive: {
                    pipeCourse: [],
                    calculationCapacity: [],
                    ventilationType: [],
                    heatRecovery: '',
                    wireType: '',
                    heating: '',
                    spaceAdjustment: '',
                    note: '',
                    docs: [],
                    zoneControlItems: {}
                },
                stepSix: {
                    stepOneComplete: false,
                    stepTwoComplete: false,
                    stepThreeComplete: false,
                    stepFourComplete: false,
                    stepFiveComplete: false,
                },
                allStepsCompleted: false,
                errors: {},
                errorsCopy: {},
                currentProgress: 1,
                countErrorsStepOne: 0,
                countErrorsStepTwo: 0,
                countErrorsStepThree: 0,
                countErrorsStepFour: 0,
                countErrorsStepFourAdditional: 0,
                countErrorsStepFive: 0,
                countErrorsStepSix: 0,
                maxArea: null,
                loader: true,
                projectAddAddress: false,
                installerAddAddress: false,
                saleAddAddress: false,
                floorsChanged: false,
                resetSteps: false,
                showResults: false,
                selectedItem: {},
                selectedItemUpdate: {
                    floor: 1,
                    qty: 1
                },
                showImage: false,
                showItemId: '',
                userRolesUnlimited: [
                    'henco-floor-super-user',
                    'henco-floor-internal-user',
                    'sales-engineer'
                ],
                currentUserRole: '',
                project: {
                    'id': null,
                    'variation': null,
                    'version': null,
                    'selection_id': null,
                    'project_id': null,
                    'project_info_id': null,
                },
                isMaterialListChanged: false,
                changeItemQty: false,
                isProjectSaved: false,
                installerUsers: {},
                wholesalerUsers: {},
                noMarginDetected: false,
                editType: null,
                currentFloor: {
                    floor: '',
                    bathroom: false,
                },
                systemsNotAllowed: false,
                timerCount: 0,
                usedProjectNumber: null,
                errorMessages: {},
                isSubmitDisabled: false,
                installerNotFound: false,
                wholesalerNotFound: false,
                services: [],
                selectedServices: [],
            }
        },

        created() {
            const self = this;

            window.onbeforeunload = function() {
                if (self.showResults == false) return self.$gettext('There is unsaved data.');
                return undefined;
            }
        },

        mounted() {
            this.getToolItems();

            this.tenant = window.Tenant;
        },

        watch: {
            showResults: {
                handler(val) {
                    window.onbeforeunload = function() {
                        if (val == false) return self.$gettext('There is unsaved data.');
                        return undefined;
                    }
                }
            },
            timerCount: {
                handler(value) {
                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }
                },
            },
        },

        methods: {
            getUserRole() {
                return axios.get(this.userRoleEndpoint).then(res => {
                    this.currentUserRole = res.data.role;

                    if (!this.userRolesUnlimited.includes(this.currentUserRole) && this.projectType === 'quote') this.maxArea = 200;

                    this.stepFourAdditional.maximumGroupLength = this.userRolesUnlimited.includes(this.currentUserRole) ? '' : 95;

                    switch (this.currentUserRole) {
                        case 'wholesaler':
                            this.wholesaler = res.data.wholesaler;
                            this.saveChoice('wholesalers', null, true);

                            if (Object.keys(this.wholesalerUsers).length > 0) {
                                this.stepOne.wholesale.userIndex = _.findKey(this.wholesalerUsers, { 'email': res.data.current_user.email })
                            }

                            this.setContact(this.stepOne.wholesale.userIndex, 'wholesaler')
                            break;
                        case 'contractor-installer':
                            this.installer = res.data.installer;
                            this.saveChoice('installers',null, true);

                            if (Object.keys(this.installerUsers).length > 0) {
                                this.stepOne.installer.userIndex = _.findKey(this.installerUsers, { 'email': res.data.current_user.email })
                            }

                            this.setContact(this.stepOne.installer.userIndex, 'installer')
                            break;
                    }
                })
            },

            getDefinedData() {
                this.errors['stepOne'] = {
                    applicationType: false,
                    installer: {},
                    project: {},
                    wholesale: {},
                }

                Object.assign(this.errors, {stepTwo: {}});

                Object.assign(this.errors, {stepThree: {}});

                Object.assign(this.errors, {stepFour: {}});

                this.errors['stepFourAdditional'] = {
                    longPipe: false,
                    maximumGroupLength: false
                };

                this.errors['stepFive'] = {
                    calculationCapacity: false,
                    heatRecovery: false,
                    heating: false,
                    pipeCourse: false,
                    spaceAdjustment: false,
                    ventilationType: false,
                    wireType: false
                }

                const selection = JSON.parse(sessionStorage.getItem('selection-data'));

                this.editType = selection.editType;

                if (this.projectType === 'quote') this.getServices(selection.id)

                selection.floors.forEach(floor => {
                    return floor.items = floor.items.filter(i => {
                        return i.item.deleted_at == null;
                    })
                })

                if (this.editType === 'material_list') {
                    this.calculateItemList(selection.floors, selection.zone_control_items, selection.prices, Boolean(selection.including_installation));
                }

                this.project = {
                    'id': selection.project_id,
                    'selection_id': selection.id,
                    'project_id': selection.project_id,
                    'project_info_id': selection.project_info_id,
                    'variation': selection.variation,
                    'version': selection.version,
                }

                this.stepOne.applicationTypeOption = selection.application_type ? selection.application_type.toLowerCase() : null;
                this.stepOne.includingInstallation = Boolean(selection.including_installation);

                if (this.editType === 'admin_data') {
                    this.stepOne.project.number = selection.project_info.project_number;

                    this.checkProjectNumber();
                } else {
                    this.getProjectNumber();
                }

                if (this.editType !== 'clone' && this.editType !== 'copy_to_quote') {
                    this.stepOne.project.name = selection.project.name;
                }

                if (this.editType !== 'clone') {
                    this.stepOne.project.address = selection.project_info.project_address;
                }

                this.stepOne.project.executionDate = selection.project_info.project_execution_date;
                this.stepOne.project.addressTwo = selection.project_info.project_address_two;
                this.stepOne.project.postcode = selection.project_info.project_city;
                this.stepOne.project.city = selection.project_info.project_postcode;
                this.projectAddAddress = !!selection.project_info.project_address_two;

                this.installer.id = selection.project_info.installer_id;
                this.installer.name = selection.project_info.installer_name;
                this.installer.address = selection.project_info.installer_address;
                this.installer.city = selection.project_info.installer_city;
                this.installer.postcode = selection.project_info.installer_postcode;

                this.stepOne.installer.name = selection.project_info.installer_name;
                this.stepOne.installer.address = selection.project_info.installer_address;
                this.stepOne.installer.addressTwo = selection.project_info.installer_address_two;
                this.stepOne.installer.postcode = selection.project_info.installer_postcode;
                this.stepOne.installer.city = selection.project_info.installer_city;
                this.stepOne.installer.contact = selection.project_info.installer_contact;
                this.stepOne.installer.phone = selection.project_info.installer_phone;
                this.stepOne.installer.mail = selection.project_info.installer_mail;
                this.installerAddAddress = !!selection.project_info.installer_address_two;

                this.getWholesalers(this.installer.id, false, true)

                this.wholesaler.id = selection.project_info.wholesaler_id;
                this.wholesaler.name = selection.project_info.wholesaler_name;
                this.wholesaler.address = selection.project_info.wholesaler_address;
                this.wholesaler.city = selection.project_info.wholesaler_city;
                this.wholesaler.postcode = selection.project_info.wholesaler_postcode;

                this.stepOne.wholesale.name = selection.project_info.wholesaler_name;
                this.stepOne.wholesale.address = selection.project_info.wholesaler_address;
                this.stepOne.wholesale.addressTwo = selection.project_info.wholesaler_address_two;
                this.stepOne.wholesale.postcode = selection.project_info.wholesaler_postcode;
                this.stepOne.wholesale.city = selection.project_info.wholesaler_city;
                this.stepOne.wholesale.contact = selection.project_info.wholesaler_contact;
                this.stepOne.wholesale.phone = selection.project_info.wholesaler_phone;
                this.stepOne.wholesale.mail = selection.project_info.wholesaler_mail;
                this.saleAddAddress = !!selection.project_info.wholesaler_address_two;

                this.getInstallers(this.wholesaler.id, false, true)

                this.floorsChanged = true;
                this.resetSteps = true;

                for (let i = 0; i < selection.floors.length; i++) {
                    const floor = selection.floors[i];
                    this.floorsNum = selection.floors.length;

                    Object.assign(this.stepTwo, {[floor.floor_name]: {
                        cooling: '',
                        coolingTemperatureOptions: '',
                        coolingTemperature: '',
                        coolingTemperatureMin: '',
                        coolingTemperatureMax: '',
                        heatSource: '',
                        heatTemperature: '',
                        heatTemperatureOptions: '',
                        heatTemperatureMin: '',
                        heatTemperatureMax: '',
                        floorFinish: '',
                        floorFinishCustom: '',
                        floorArea: ''
                    }});

                    Object.assign(this.stepThree, {[floor.floor_name]: {
                        main: {
                            system: '',
                            subSystems: '',
                            subSystem: '',
                            diameters: '',
                            tubeSpecification: ''
                        },
                        additional: {
                            system: '',
                            subSystems: '',
                            subSystem: '',
                            diameters: '',
                            tubeSpecification: ''
                        },
                        additionalSystem: false,
                    }});

                    Object.assign(this.stepFour, {[floor.floor_name]: {
                        main: {
                            insulations: '',
                            insulation: '',
                            manifold: '',
                            manifoldLocation: '',
                            manifoldLocationCustom: '',
                            tubes: '',
                            tube: '',
                            tubeDiameters: '',
                            tubeDiameter: '',
                            displacementDistance: '',
                            bathroom: false,
                            bathroomArea: '',
                            screed: '',
                            screedValue: '',
                        },
                        additional: {
                            insulations: '',
                            insulation: '',
                            manifold: '',
                            manifoldLocation: '',
                            manifoldLocationCustom: '',
                            tubes: '',
                            tube: '',
                            tubeDiameters: '',
                            tubeDiameter: '',
                            displacementDistance: '',
                            screed: '',
                            screedValue: '',
                        }
                    }});

                    this.stepTwo[floor.floor_name] = {
                        cooling: floor.cooling.toString(),
                        coolingTemperature: floor.cooling_temperature ?? '',
                        coolingTemperatureMin: floor.cooling_temperature_min ?? '',
                        coolingTemperatureMax: floor.cooling_temperature_max ?? '',
                        heatSource: this.heatSources.find(el => el.id === floor.heat_source_id) === undefined ? '' : floor.heat_source_id,
                        heatTemperature: floor.heat_temperature ?? '',
                        heatTemperatureMin: floor.heat_temperature_min ?? '',
                        heatTemperatureMax: floor.heat_temperature_max ?? '',
                        insulate: floor.insulate,
                        floorFinish: this.floorFinish.find(el => el.id === floor.floor_finish_id) === undefined ? '' : parseInt(floor.floor_finish_id),
                        floorFinishCustom: floor.floor_finish_custom ? floor.floor_finish_custom : '',
                        floorArea: floor.floor_area.toString()
                    }

                    this.changeTemp(floor.floor_name, floor.heat_source_id, true)

                    this.stepThree[floor.floor_name]['main'] = {
                        system: floor.systems[0].system && this.systems.find(system => system.id == floor.systems[0].system.id && system.name === floor.systems[0].system.name) ? floor.systems[0].system.name : '',
                        subSystem: floor.systems[0].system && floor.systems[0].sub_system ? floor.systems[0].sub_system.name : '',
                        subSystems: '',
                        diameters: '',
                        tubeSpecification: ''
                    }

                    this.stepFour[floor.floor_name]['main'] = {
                        insulation: floor.systems[0].insulation ?? '',
                        manifold: this.manifolds.find(el => el.id === floor.systems[0].manifold_id) === undefined ? '' : floor.systems[0].manifold_id,
                        manifoldLocation: floor.systems[0].manifold_location.replaceAll(' ', '_').toLowerCase(),
                        manifoldLocationCustom: floor.systems[0].manifold_location_custom ? floor.systems[0].manifold_location_custom.replaceAll(' ', '_').toLowerCase() : '',
                        tube: this.tubes.find(el => el.id === floor.systems[0].tube_id) === undefined ? '' : floor.systems[0].tube_id,
                        tubeDiameter: floor.systems[0].tube_diameter,
                        displacementDistance: floor.systems[0].displacement_distance,
                        bathroom: !!floor.systems[0].bathroom_area,
                        bathroomArea: floor.systems[0].bathroom_area,
                        screed: this.screeds.find(el => el.id === floor.systems[0].screed_id) === undefined ? '' : floor.systems[0].screed_id,
                        screedValue: floor.systems[0].screed_value,
                    };

                    if (floor.systems[0].system && floor.systems[0].sub_system) {
                        if (['contractor-installer', 'wholesaler'].includes(this.currentUserRole)) {
                            if (this.systems.find(el => el.name === floor.systems[0].system.name)) {
                                if (!this.getSubSystem(floor.floor_name, floor.systems[0].system.name, 'main', true, floor.systems[0].sub_system)) {
                                    this.systemsNotAllowed = true;
                                    this.showResults = true;

                                    this.goHome(5000)

                                    return
                                }
                            } else {
                                this.systemsNotAllowed = true;
                                this.showResults = true;

                                this.goHome(5000)

                                return
                            }
                        } else {
                            this.getSubSystem(floor.floor_name, floor.systems[0].system.name, 'main', true, floor.systems[0].sub_system)
                        }

                        if (this.tubes.find(el => el.id === floor.systems[0].tube_id) !== undefined) {
                            this.setTubeSize(floor.floor_name, floor.systems[0].tube_id, 'main', true);
                        }
                    }

                    if (floor.systems[1]) this.stepThree[floor.floor_name]['additionalSystem'] = true;

                    if (floor.systems[1] && floor.systems[1].system && floor.systems[1].sub_system) {
                        this.stepThree[floor.floor_name]['additional'] = {
                            system: floor.systems[1].system && this.systems.find(system => system.id == floor.systems[1].system.id && system.name === floor.systems[1].system.name) ? floor.systems[1].system.name : '',
                            subSystem: floor.systems[1].system && floor.systems[1].sub_system ? floor.systems[1].sub_system.name : '',
                            subSystems: '',
                            diameters: '',
                            tubeSpecification: ''
                        }

                        this.stepFour[floor.floor_name]['additional'] = {
                            insulation: floor.systems[1].insulation ?? '',
                            manifold: this.manifolds.find(el => el.id === floor.systems[1].manifold_id) === undefined ? '' : floor.systems[1].manifold_id,
                            manifoldLocation: floor.systems[1].manifold_location.replaceAll(' ', '_').toLowerCase(),
                            manifoldLocationCustom: floor.systems[1].manifold_location_custom ? floor.systems[1].manifold_location_custom.replaceAll(' ', '_').toLowerCase() : '',
                            tube: this.tubes.find(el => el.id === floor.systems[1].tube_id) === undefined ? '' : floor.systems[1].tube_id,
                            tubeDiameter: floor.systems[1].tube_diameter,
                            displacementDistance: floor.systems[1].displacement_distance,
                            screed: this.screeds.find(el => el.id === floor.systems[1].screed_id) === undefined ? '' : floor.systems[1].screed_id,
                            screedValue: floor.systems[1].screed_value,
                        };

                        if (['contractor-installer', 'wholesaler'].includes(this.currentUserRole)) {
                            if (this.systems.find(el => el.name === floor.systems[1].system.name)) {
                                if (!this.getSubSystem(floor.floor_name, floor.systems[1].system.name, 'additional', true, floor.systems[1].sub_system)) {
                                    this.systemsNotAllowed = true;
                                    this.showResults = true;

                                    this.goHome(5000)

                                    return
                                }
                            } else {
                                this.systemsNotAllowed = true;
                                this.showResults = true;

                                this.goHome(5000)

                                return
                            }
                        } else {
                            this.getSubSystem(floor.floor_name, floor.systems[1].system.name, 'additional', true, floor.systems[1].sub_system)
                        }

                        if (this.tubes.find(el => el.id === floor.systems[1].tube_id) !== undefined) {
                            this.setTubeSize(floor.floor_name, floor.systems[1].tube_id, 'additional', true);
                        }
                    }

                    Object.assign(this.errors.stepTwo, {[floor.floor_name]: ''});

                    Object.assign(this.errors.stepThree, {[floor.floor_name]: {}});

                    Object.assign(this.errors.stepFour, {[floor.floor_name]: {}});
                }

                this.toggleAdjustments(selection.wire_type.replaceAll(' ', '_').toLowerCase());

                this.stepFourAdditional.maximumGroupLength = selection.maximum_group_length;

                if (!['henco-floor-super-user', 'henco-floor-internal-user'].includes(this.currentUserRole) && selection.maximum_group_length > 95) {
                    this.stepFourAdditional.maximumGroupLength = 95;
                }

                this.stepFourAdditional.longPipe = selection.long_pipe ? '1' : '0';

                this.stepFive.wireType = selection.wire_type.replaceAll(' ', '_').toLowerCase();
                this.stepFive.heating = selection.heating ? selection.heating.replaceAll(' ', '_').toLowerCase() : '';
                this.stepFive.spaceAdjustment = selection.space_adjustment ?? '';
                this.stepFive.pipeCourse = selection.pipe_course ?? [];
                this.stepFive.calculationCapacity = selection.calculation_capacity ?? [];
                this.stepFive.ventilationType = selection.ventilation_type ?? [];
                this.stepFive.heatRecovery = selection.heat_recovery ?? '';
                this.stepFive.note = selection.note ?? '';

                if (selection.zone_control_items) {
                    this.getZoneItems(selection.zone_control_items);
                }

                this.checkFormSteps();

                sessionStorage.removeItem('selection-data');
            },

            async getToolItems() {
                await this.getSystems();
                await this.getManifolds();
                await this.getTubes();
                await this.getTechData();
                await this.getScreeds();
                await this.getUserRole();

                if (this.projectType === 'quote') await this.getGroups();

                if (sessionStorage.getItem('selection-data')) {
                    this.getDefinedData();
                } else {
                    if (this.userRolesUnlimited.includes(this.currentUserRole)) {
                        this.getWholesalers();
                        this.getInstallers();
                    }

                    await this.getProjectNumber();

                    if (this.projectType === 'quote') await this.getServices();
                }

                this.loader = false;
            },

            getGroups() {
                axios.get(this.groupsEndpoint).then(res => {
                    this.itemGroups = res.data;
                });
            },

            // When you change the step from top circles steps or 'Previous' or 'Next' buttons.
            setSteps(step) {
                this.errorsCopy = {
                    stepOne: [],
                    stepTwo: [],
                    stepThree: [],
                    stepFour: [],
                    stepFive: [],
                };

                this.checkFormSteps();

                this.currentProgress = step;
            },

            // When you change the cooling for each floor.
            resetCooling(floor) {
                this.stepTwo[floor].coolingTemperature = '';
                this.stepTwo[floor].heatSource = '';
                this.stepTwo[floor].heatTemperature = '';

                this.stepThree[floor].main.system = '';
                this.stepThree[floor].main.subSystem = '';
                this.stepThree[floor].main.diameters = '';
                this.stepThree[floor].main.tubeSpecification = '';

                this.stepThree[floor].additional.system = '';
                this.stepThree[floor].additional.subSystem = '';
                this.stepThree[floor].additional.diameters = '';
                this.stepThree[floor].additional.tubeSpecification = '';

                this.stepFour[floor].main.insulations = '';
                this.stepFour[floor].main.insulation = '';
                this.stepFour[floor].additional.insulations = '';
                this.stepFour[floor].additional.insulation = '';
                this.stepFour[floor].main.manifold = '';
                this.stepFour[floor].additional.manifold = '';

                this.errorsCopy = {};

                this.checkFormSteps();
            },

            setErrorMessages(step, key = null, type = null, additional = false) {
                if (step === 3) {
                    return this.errorsCopy.stepThree[key] = ' ' + this.$gettext('has different cooling than Floor 1, please return and change it to apply!');
                }

                if (step === 4) {
                    if (type === 'no-insulation') {
                        if (additional) {
                            return this.errorsCopy.stepFour[key] = Object.assign({'additional': ' ' + this.$gettext('additional sub system (') +
                                this.capitalize(this.stepThree[key].additional.subSystem.replaceAll('_', ' ')) +
                                this.$gettext(') has no insulation, please return and change the sub system to apply!')
                            }, this.errorsCopy.stepFour[key]);
                        }

                        return this.errorsCopy.stepFour[key] = Object.assign({
                            'main': this.$gettext('main sub system (') +
                            this.capitalize(this.stepThree[key].main.subSystem.replaceAll('_', ' ')) +
                            this.$gettext(') has no insulation, please return and change the sub system to apply!')
                        }, this.errorsCopy.stepFour[key])
                    } else if (type === 'has-insulation') {
                        if (additional) {
                            return this.errorsCopy.stepFour[key] = Object.assign({
                                'additional': ' ' + this.$gettext('additional sub system (') +
                                this.capitalize(this.stepThree[key].additional.subSystem.replaceAll('_', ' ')) +
                                this.$gettext(') has insulation, please return and change the sub system to apply!')
                            }, this.errorsCopy.stepFour[key]);
                        }

                        return this.errorsCopy.stepFour[key] = Object.assign({'main': this.$gettext('main sub system (') +
                            this.capitalize(this.stepThree[key].main.subSystem.replaceAll('_', ' ')) +
                            this.$gettext(') has insulation, please return and change the sub system to apply!')
                        }, this.errorsCopy.stepFour[key]);
                    } else if (type === 'different-tube') {
                        if (additional) {
                            return this.errorsCopy.stepFour[key] = Object.assign({
                                'additional': ' ' + this.$gettext('additional sub system (') +
                                this.capitalize(this.stepThree[key].additional.subSystem.replaceAll('_', ' ')) +
                                this.$gettext(') has different tube specification, please return and change the sub system to apply!')
                            }, this.errorsCopy.stepFour[key]);
                        }

                        return this.errorsCopy.stepFour[key] = Object.assign({
                            'main': ' ' + this.$gettext('main sub system (') +
                            this.capitalize(this.stepThree[key].main.subSystem.replaceAll('_', ' ')) +
                            this.$gettext(') has different tube specification, please return and change the sub system to apply!')
                        }, this.errorsCopy.stepFour[key]);
                    } else if (type === 'unsupported-tubes') {
                        if (additional) {
                            return this.errorsCopy.stepFour[key] = Object.assign({ 'additional': ' ' + this.$gettext('additional sub system (') +
                                this.capitalize(this.stepThree[key].additional.subSystem.replaceAll('_', ' ')) +
                                this.$gettext(') does not support Floor 1 tube') + ' ' +
                                this.capitalize(this.stepFour['floor1'].additional.tubes.filter(el => this.stepFour['floor1'].additional.tube === el.id)[0].display_name) + '!'
                            }, this.errorsCopy.stepFour[key]);
                        }

                        return this.errorsCopy.stepFour[key] = Object.assign({ 'main': ' ' + this.$gettext('main sub system (') +
                            this.capitalize(this.stepThree[key].main.subSystem.replaceAll('_', ' ')) +
                            this.$gettext(') does not support Floor 1 tube') + ' ' +
                            this.capitalize(this.stepFour['floor1'].main.tubes.filter(el => this.stepFour['floor1'].main.tube === el.id)[0].display_name) + '!'
                        }, this.errorsCopy.stepFour[key]);
                    } else if (type === 'unsupported-tube-diameters') {
                        if (additional) {
                            this.errorsCopy.stepFour[key] = Object.assign({ 'additional': ' ' + this.$gettext('additional sub system (') +
                                this.capitalize(this.stepThree[key].additional.subSystem.replaceAll('_', ' ')) +
                                this.$gettext(') does not support Floor 1 tube (') + ' ' +
                                this.capitalize(this.stepFour['floor1'].additional.tubes.filter(el => this.stepFour['floor1'].additional.tube === el.id)[0].display_name) +
                                this.$gettext(') diameters!')
                            }, this.errorsCopy.stepFour[key]);
                        }

                        return this.errorsCopy.stepFour[key] = Object.assign({'main': ' ' + this.$gettext('main sub system (') +
                            this.capitalize(this.stepThree[key].main.subSystem.replaceAll('_', ' ')) +
                            this.$gettext(') does not support Floor 1 tube (') + ' ' +
                            this.capitalize(this.stepFour['floor1'].main.tubes.filter(el => this.stepFour['floor1'].main.tube === el.id)[0].display_name) +
                            this.$gettext(') diameters!')
                        }, this.errorsCopy.stepFour[key]);
                    } else if (type === 'different-cooling') {
                        this.errorsCopy.stepFour[key] = Object.assign({
                            'main': ' ' + this.$gettext('has different cooling than Floor 1, please return and change it to apply!')
                        }, this.errorsCopy.stepFour[key])
                    }
                }
            },

            // When every option on every Floor 1 step is checked
            copyFloor(step) {
                if (step === 'stepTwo') {
                    for (const key in this[step]) {
                        this[step][key] = JSON.parse(JSON.stringify(this[step]['floor1']));
                    }

                    for (const key in this.stepThree) {
                        if (key !== 'floor1') {
                            this.stepThree[key] = {
                                main: {
                                    system: '',
                                    subSystems: '',
                                    subSystem: '',
                                    diameters: '',
                                    tubeSpecification: '',
                                },
                                additional: {
                                    system: '',
                                    subSystems: '',
                                    subSystem: '',
                                    diameters: '',
                                    tubeSpecification: '',
                                },
                                additionalSystem: false,
                            };
                        }
                    }

                    for (const key in this.stepFour) {
                       if (key !== 'floor1') {
                           this.stepFour[key] = {
                               main: {
                                   insulations: '',
                                   insulation: '',
                                   manifold: '',
                                   manifoldLocation: '',
                                   manifoldLocationCustom: '',
                                   tubes: '',
                                   tube: '',
                                   tubeDiameters: '',
                                   tubeDiameter: '',
                                   displacementDistance: '',
                                   bathroom: false,
                                   bathroomArea: '',
                                   screed: '',
                                   screedValue: '',
                               },
                               additional: {
                                   insulations: '',
                                   insulation: '',
                                   manifold: '',
                                   manifoldLocation: '',
                                   manifoldLocationCustom: '',
                                   tubes: '',
                                   tube: '',
                                   tubeDiameters: '',
                                   tubeDiameter: '',
                                   displacementDistance: '',
                                   screed: '',
                                   screedValue: '',
                               }
                           };
                       }
                    }
                }

                if (step === 'stepThree') {
                    this.errorsCopy.stepThree = {};

                    for (const key in this[step]) {
                        if (key !== 'floor1') {
                            if (this.stepTwo['floor1'].cooling === this.stepTwo[key].cooling) {
                                this[step][key] = JSON.parse(JSON.stringify(this[step]['floor1']));

                                this.setConnections(key, this[step][key].main.system, this[step][key].main.subSystem, 'main')

                                if (this[step][key].additionalSystem) {
                                    this.setConnections(key, this[step][key].additional.system, this[step][key].additional.subSystem, 'additional')
                                }
                            } else {
                                this.setErrorMessages(3, key);
                            }
                        }
                    }
                }

                if (step === 'stepFour') {
                    this.errorsCopy.stepFour = {};

                    for (const key in this[step]) {
                        if (key !== 'floor1') {
                            if (this.stepTwo['floor1'].cooling === this.stepTwo[key].cooling) {
                                if (this.stepFour['floor1'].main.insulations.length !== this.stepFour[key].main.insulations.length) {
                                    if (this.stepFour['floor1'].main.insulations.length > 0 && this.stepFour[key].main.insulations.length === 0) {
                                        this.setErrorMessages(4, key, 'no-insulation')
                                    } else if (this.stepFour['floor1'].main.insulations.length === 0 && this.stepFour[key].main.insulations.length > 0) {
                                        this.setErrorMessages(4, key, 'has-insulation')
                                    }
                                }

                                if (this.stepThree['floor1'].additionalSystem && this.stepFour['floor1'].additional.insulations.length !== this.stepFour[key].additional.insulations.length) {
                                    if (this.stepFour['floor1'].additional.insulations.length > 0 && this.stepFour[key].additional.insulations.length === 0) {
                                        this.setErrorMessages(4, key, 'no-insulation', true)
                                    } else if (this.stepFour['floor1'].additional.insulations.length === 0 && this.stepFour[key].additional.insulations.length > 0) {
                                        this.setErrorMessages(4, key, 'has-insulation', true)
                                    }
                                }

                                if (this.stepThree['floor1'].main.tubeSpecification !== this.stepThree[key].main.tubeSpecification) {
                                    this.setErrorMessages(4, key, 'different-tube')
                                }

                                if (this.stepThree['floor1'].additionalSystem && this.stepThree['floor1'].additional.tubeSpecification !== this.stepThree[key].additional.tubeSpecification) {
                                    this.setErrorMessages(4, key, 'different-tube', true)
                                }

                                const self = this;

                                if (!Object.values(this.stepFour[key].main.tubes)
                                    .some(function (el) {return self.stepFour['floor1'].main.tube === el.id;})) {
                                    this.setErrorMessages(4, key, 'unsupported-tube')
                                }

                                if (this.stepFour[key].additional.tubes && !Object.values(this.stepFour[key].additional.tubes)
                                    .some(function (el) {return self.stepFour['floor1'].additional.tube === el.id;})) {
                                    this.setErrorMessages(4, key, 'unsupported-tube', true)
                                }

                                if (!JSON.parse(this.stepThree[key].main.diameters).includes(this.stepFour['floor1'].main.tubeDiameter)) {
                                    this.setErrorMessages(4, key, 'unsupported-tube-diameters')
                                }

                                if (this.stepThree[key].additional.diameters &&
                                    (!JSON.parse(this.stepThree[key].additional.diameters).includes(this.stepFour['floor1'].additional.tubeDiameter))) {
                                    this.setErrorMessages(4, key, 'unsupported-tube-diameters', true)
                                }

                                if (Object.keys(this.errorsCopy.stepFour).length === 0) {
                                    this[step][key].main = JSON.parse(JSON.stringify(this[step]['floor1'].main));

                                    if (this.stepThree['floor1'].additionalSystem) {
                                        this[step][key].additional = JSON.parse(JSON.stringify(this[step]['floor1'].additional));
                                    }
                                }

                                if (Object.keys(this.errorsCopy.stepFour).length > 0 &&
                                    (!this.errorsCopy.stepFour[key]) || (this.errorsCopy.stepFour[key] && !this.errorsCopy.stepFour[key].hasOwnProperty('main'))) {
                                    this[step][key].main = JSON.parse(JSON.stringify(this[step]['floor1'].main));
                                }

                                if (this.stepThree['floor1'].additionalSystem && Object.keys(this.errorsCopy.stepFour).length > 0 &&
                                    (!this.errorsCopy.stepFour[key]) || (this.errorsCopy.stepFour[key] && !this.errorsCopy.stepFour[key].hasOwnProperty('additional'))) {
                                    this[step][key].additional = JSON.parse(JSON.stringify(this[step]['floor1'].additional));
                                }
                            } else {
                                this.setErrorMessages(4, key, 'different-cooling')
                            }
                        }
                    }
                }

                this.checkFormSteps();
            },

            // Clears copy floor errors
            clearError(step, floor, type = null) {
                if (type) {
                    delete this.errorsCopy[step][floor][type];
                } else {
                    delete this.errorsCopy[step][floor];
                }

                if (type && Object.keys(this.errorsCopy[step][floor]).length === 0) {
                    delete this.errorsCopy[step][floor];
                }

                if (Object.keys(this.errorsCopy[step]).length === 0) {
                    delete this.errorsCopy[step];
                }

                this.checkFormSteps();
            },

            // Get all systems from DB
            async getSystems() {
                return axios.get(this.systemsEndpoint, {
                    params: {
                        type: this.projectType
                    }
                }).then(response => {
                    this.systems = response.data;
                })
            },

            // Get all manifolds from DB
            async getManifolds() {
                return axios.get(this.manifoldsEndpoint).then(response => {
                    this.manifolds = response.data;
                })
            },

            // Get all tubes from DB
            async getTubes() {
                return axios.get(this.tubesEndpoint).then(response => {
                    this.tubes = response.data;
                })
            },

            // Get all tech data from DB
            async getTechData() {
                return axios.get(this.techDataEndpoint).then(response => {
                    const data = response.data;

                    this.heatSources = data.filter(el => el.type === 'heat_source');
                    this.floorFinish = data.filter(el => el.type === 'floor_finish');
                })
            },

            // Get all screeds from DB
            async getScreeds() {
                return axios.get(this.screedsEndpoint).then(response => {
                    this.screeds = response.data;
                })
            },

            async getProjectNumber() {
                axios.get(this.projectNumberEndpoint).then(res => {
                    this.stepOne.project.number = res.data;

                    this.checkFormSteps();
                }).catch(error => {

                })
            },

            // When you select tube, the dropdown for sizes appear and fills in.
            setTubeSize(floor, tube, type, predefined = false) {
                let allowed = '';

                if (predefined) {
                    if (this.stepThree[floor][type].diameters !== '') {
                        allowed = JSON.parse(this.stepThree[floor][type].diameters)
                    }

                    const tubes = JSON.parse(this.tubes.filter(function (el) {
                        return el.id == tube
                    })[0]['diameters_formatted']);

                    if (allowed.length) {
                        this.stepFour[floor][type].tubeDiameters = tubes.filter(item => allowed.includes(item));
                    } else {
                        this.stepFour[floor][type].tubeDiameters = tubes;
                    }
                } else {
                    this.stepFour[floor][type].tubeDiameter = '';

                    if (tube !== '') {
                        if (this.stepThree[floor][type].diameters !== '') {
                            allowed = JSON.parse(this.stepThree[floor][type].diameters)
                        }

                        const tubes = JSON.parse(this.tubes.filter(function (el) {
                            return el.id == tube
                        })[0]['diameters_formatted']);

                        this.stepFour[floor][type].tubeDiameters = tubes;

                        if (allowed.length) {
                            this.stepFour[floor][type].tubeDiameters = tubes.filter(item => allowed.includes(item));
                        }
                    }

                    this.checkFormSteps();
                }
            },

            // When you select the system from step three.
            getSubSystem(floor, system, type, predefined = false, checkSubSystem = null) {
                if (predefined) {
                    this.stepThree[floor][type]['subSystems'] = Object.values(this.systems.filter(el => el.name === system)).length > 0 ?
                        this.systems.filter(el => el.name === system)[0]['sub_systems'] : [];

                    if (this.stepThree[floor][type]['subSystems'].find(el => el.name === checkSubSystem.name)) {
                        this.setConnections(floor, system, this.stepThree[floor][type].subSystem, type, true);

                        return true;
                    }

                    return false;
                } else {
                    this.stepThree[floor][type].subSystem = '';
                    this.stepThree[floor][type].subSystems = '';
                    this.stepThree[floor][type].tubeSpecification = '';
                    this.stepFour[floor][type].insulations = '';
                    this.stepFour[floor][type].insulation = '';

                    if (system !== '') {
                        if (this.errorsCopy.stepThree && this.errorsCopy.stepThree[floor]) {
                            delete this.errorsCopy.stepThree[floor];
                        }

                        this.stepThree[floor][type].subSystems = this.systems.filter(el => el.name === system)[0]['sub_systems'];

                        if (Object.keys(this.stepThree[floor][type].subSystems).length === 1) {
                            this.stepThree[floor][type].subSystem = this.stepThree[floor][type].subSystems[0].name;
                        }

                        this.setConnections(floor, system, this.stepThree[floor][type].subSystem, type)
                    }

                    this.checkFormSteps();
                }
            },

            // When you select the sub system from step three, sets insulation, diameters, tubes, tube specification
            setConnections(floor, system, subSystem, type, predefined = false) {
                if (predefined) {
                    const currentSubSystem = this.stepThree[floor][type].subSystems.filter(el => el.name === subSystem)[0];

                    this.stepFour[floor][type].tubes = currentSubSystem['tube_formatted'];
                    this.stepThree[floor][type].diameters = currentSubSystem['diameters_formatted'];
                    this.stepThree[floor][type].tubeSpecification = currentSubSystem['tube_specification'];

                    if (currentSubSystem.insulation === 1) {
                        this.stepFour[floor][type].insulations = currentSubSystem['insulations']
                    } else {
                        this.stepFour[floor][type].insulations = '';
                    }
                } else {
                    this.stepFour[floor][type].insulation = '';
                    this.stepFour[floor][type].insulations = '';
                    this.stepFour[floor][type].tube = '';
                    this.stepThree[floor][type].diameters = '';
                    this.stepThree[floor][type].tubeSpecification = '';

                    if (subSystem !== '') {
                        if (this.errorsCopy.stepThree && this.errorsCopy.stepThree[floor]) delete this.errorsCopy.stepThree[floor];

                        if (this.errorsCopy.stepFour && this.errorsCopy.stepFour[floor]) delete this.errorsCopy.stepFour[floor];

                        const currentSubSystem = this.stepThree[floor][type].subSystems.filter(el => el.name === subSystem)[0];

                        this.stepFour[floor][type].tubes = currentSubSystem['tube_formatted'];
                        this.stepThree[floor][type].diameters = currentSubSystem['diameters_formatted'];
                        this.stepThree[floor][type].tubeSpecification = currentSubSystem['tube_specification'];

                        if (currentSubSystem.insulation === 1) {
                            this.stepFour[floor][type].insulations = currentSubSystem['insulations']
                        }
                    }

                    this.checkFormSteps();
                }
            },

            // When you toggle a sub system for each floor.
            toggleSubSystem(floor) {
                this.stepThree[floor].additionalSystem = !this.stepThree[floor].additionalSystem;

                this.stepThree[floor].additional = {
                    system: '',
                    subSystems: '',
                    subSystem: '',
                    diameters: '',
                    tubeSpecification: '',
                }

                this.stepFour[floor].additional = {
                    insulations: '',
                    insulation: '',
                    manifold: '',
                    manifoldLocation: '',
                    manifoldLocationCustom: '',
                    tubes: '',
                    tube: '',
                    tubeDiameters: '',
                    tubeDiameter: '',
                    displacementDistance: '',
                    screed: '',
                    screedValue: '',
                }

                this.checkFormSteps();
            },

            resetTubes() {
                for (let i = 1; i <= Object.keys(this.stepFour).length; i++) {
                    this.stepFour['floor' + i].main.tube = '';

                    if (this.stepFour['floor' + i].additional) this.stepFour['floor' + i].additional.tube = '';
                }

                this.checkFormSteps();
            },

            // Validation for step 1
            validateStepOneKeys(additionalInputs) {
                const optionalRequiredKeys = ['addressTwo'];

                return function([key, value]) {
                    if (additionalInputs) {
                        return value === '' || value === null;
                    } else {
                        return !optionalRequiredKeys.includes(key) && (value === '' || value === null);
                    }
                }
            },

            // Validation for step 2
            validateStepTwoKeys(obj, filter) {
                for (const key in obj) {
                    let floorKey = obj[key];

                    if (typeof floorKey === "object" && key !== 'heatTemperatureOptions' && key !== 'coolingTemperatureOptions') {
                        this.validateStepTwoKeys(floorKey, filter);

                        delete floorKey['coolingTemperatureOptions']
                        delete floorKey['coolingTemperature']
                        delete floorKey['coolingTemperatureMin']
                        delete floorKey['coolingTemperatureMax']
                        delete floorKey['heatSource']
                        delete floorKey['heatTemperature']
                        delete floorKey['heatTemperatureOptions']
                        delete floorKey['heatTemperatureMin']
                        delete floorKey['heatTemperatureMax']
                        delete floorKey['floorFinishCustom']

                        if (this.stepTwo[key]['cooling'] !== '') {
                            if (this.stepTwo[key]['heatSource'] !== '') {
                                delete floorKey['heatSource'];

                                if (this.stepTwo[key]['heatTemperature'] === 'custom_input') {
                                    if (this.stepTwo[key]['heatTemperatureMin'] === '' || (this.stepTwo[key]['heatTemperatureMin'] < 25 || this.stepTwo[key]['heatTemperatureMin'] > 50)) {
                                        floorKey['heatTemperatureMin'] = '';
                                    }

                                    if (this.stepTwo[key]['heatTemperatureMax'] === '' || (this.stepTwo[key]['heatTemperatureMax'] < 25 || this.stepTwo[key]['heatTemperatureMax'] > 50)) {
                                        floorKey['heatTemperatureMax'] = '';
                                    }
                                }

                                if (this.stepTwo[key]['heatTemperature'] === '' && this.stepTwo[key]['heatTemperatureOptions'] !== '') {
                                    floorKey['heatTemperature'] = '';
                                }

                                if (this.stepTwo[key]['coolingTemperature'] === 'custom_input') {
                                    if (this.stepTwo[key]['coolingTemperatureMin'] === '' || (this.stepTwo[key]['coolingTemperatureMin'] < 16 || this.stepTwo[key]['coolingTemperatureMin'] > 23)) {
                                        floorKey['coolingTemperatureMin'] = '';
                                    }

                                    if (this.stepTwo[key]['coolingTemperatureMax'] === '' || (this.stepTwo[key]['coolingTemperatureMax'] < 16 || this.stepTwo[key]['coolingTemperatureMax'] > 23)) {
                                        floorKey['coolingTemperatureMax'] = '';
                                    }
                                }

                                if (this.stepTwo[key]['coolingTemperature'] === '' && this.stepTwo[key]['cooling'] === '1' && this.stepTwo[key]['coolingTemperatureOptions'] !== '') {
                                    floorKey['coolingTemperature'] = '';
                                }
                            } else {
                                obj[key]['heatSource'] = '';
                            }
                        }

                        if (this.stepTwo[key]['floorFinish'] !== '') {
                            if (!this.floorFinish.filter(el => el.id === this.stepTwo[key]['floorFinish'])[0].values
                                && this.stepTwo[key]['floorFinishCustom'] === '') {
                                obj[key]['floorFinishCustom'] = '';
                            }
                        } else {
                            obj[key]['floorFinish'] = '';
                        }


                        if (Object.keys(obj[key]).length > 0) {
                            this.countErrorsStepTwo += Object.keys(floorKey).length;
                        } else {
                            this.errors.stepTwo = {...this.errors.stepTwo, [key]: ""}
                        }
                    } else {
                        if (filter(floorKey) === false) delete obj[key];
                    }

                    if (JSON.stringify(floorKey) === "{}") delete obj[key];
                }
            },

            // Validation for step 3
            validateStepThreeKeys(obj, filter) {
                for (const key in obj) {
                    if (obj[key]) {
                        const valMain = obj[key]['main'];
                        const valAdditional = obj[key]['additional'];

                        delete obj[key]['additionalSystem']

                        if (typeof valMain === "object" && key['main'] !== 'subSystems' && key['main'] !== 'tubeSpecification') {
                            this.validateStepThreeKeys(valMain, filter);

                            delete obj[key]['main']['system']
                            delete obj[key]['main']['subSystem'];
                            delete obj[key]['main']['subSystems'];
                            delete obj[key]['main']['diameters'];
                            delete obj[key]['main']['tubeSpecification'];

                            if (this.stepThree[key]['main']['system'] !== '') {
                                if (this.stepThree[key]['main']['subSystem'] === '') obj[key]['main']['subSystem'] = '';
                            } else {
                                obj[key]['main']['system'] = '';
                            }

                            if (Object.keys(obj[key]['main']).length > 0) {
                                this.countErrorsStepThree += Object.keys(obj[key]['main']).length;
                            } else {
                                this.errors.stepThree[key] = {...this.errors.stepThree[key], ['main']: ""}
                            }
                        } else {
                            if (filter(valMain) === false) delete obj[key]['main'];
                        }

                        if (typeof valAdditional === "object" && key['main'] !== 'subSystems' && key['main'] !== 'tubeSpecification') {
                            this.validateStepThreeKeys(valAdditional, filter);

                            delete obj[key]['additional']['system']
                            delete obj[key]['additional']['subSystem']
                            delete obj[key]['additional']['subSystems']
                            delete obj[key]['additional']['diameters']
                            delete obj[key]['additional']['tubeSpecification']

                            if (this.stepThree[key]['additionalSystem']) {
                                if (this.stepThree[key]['additional']['system'] !== '') {
                                    if (this.stepThree[key]['additional']['subSystem'] === '') {
                                        obj[key]['additional']['subSystem'] = '';
                                    }
                                } else {
                                    obj[key]['additional']['system'] = '';
                                }

                                if (Object.keys(obj[key]['additional']).length > 0) {
                                    this.countErrorsStepThree += Object.keys(obj[key]['additional']).length;
                                } else {
                                    this.errors.stepThree[key] = {...this.errors.stepThree[key], ['additional']: ""}
                                }
                            }
                        } else {
                            if (filter(valAdditional) === false) delete obj[key]['additional'];
                        }

                        if (JSON.stringify(valMain) === "{}") delete obj[key]['main'];

                        if (JSON.stringify(valAdditional) === "{}") delete obj[key]['additional'];
                    } else {
                        delete obj[key];
                    }
                }
            },

            // Validation for step 4
            validateStepFourKeys(obj, filter) {
                for (const key in obj) {
                    if (obj[key]) {
                        const valMain = obj[key]['main'];
                        const valAdditional = obj[key]['additional'];

                        if (typeof valMain === "object" && key['main'] !== 'tubeDiameters'
                            && key['main'] !== 'tubes' && key['main'] !== 'insulations') {
                            this.validateStepFourKeys(valMain, filter);

                            delete obj[key]['main']['insulations']
                            delete obj[key]['main']['insulation']
                            delete obj[key]['main']['tubes']
                            delete obj[key]['main']['tube']
                            delete obj[key]['main']['tubeDiameter']
                            delete obj[key]['main']['tubeDiameters']
                            delete obj[key]['main']['screed']
                            delete obj[key]['main']['screedValue']
                            delete obj[key]['main']['displacementDistance']
                            delete obj[key]['main']['bathroom']
                            delete obj[key]['main']['bathroomArea']
                            delete obj[key]['main']['manifoldLocation']
                            delete obj[key]['main']['manifoldLocationCustom']
                            delete obj[key]['main']['manifold']

                            if (this.stepThree[key]['main']['system'] !== ''
                                && this.stepThree[key]['main']['subSystem'] !== ''
                                && this.stepFour[key]['main']['insulation'] === ''
                                && this.stepFour[key]['main']['insulations'].length > 0) {
                                obj[key]['main']['insulation'] = '';
                            }

                            if (this.stepFour[key]['main']['tube'] !== '') {
                                if (this.stepFour[key]['main']['tubeDiameter'] === '') {
                                    obj[key]['main']['tubeDiameter'] = '';
                                }
                            } else {
                                obj[key]['main']['tube'] = '';
                            }

                            if (this.stepFour[key]['main']['screed'] !== '') {
                                if (this.stepFour[key]['main']['screedValue'] === '') {
                                    obj[key]['main']['screedValue'] = '';
                                }
                            } else {
                                obj[key]['main']['screed'] = '';
                            }

                            if (this.stepFour[key]['main']['manifold'] !== '') {
                                if (this.stepFour[key]['main']['manifoldLocation'] !== '') {
                                    if (this.stepFour[key]['main']['manifoldLocation'] === 'custom_input' && this.stepFour[key]['main']['manifoldLocationCustom'] === '') {
                                        obj[key]['main']['manifoldLocationCustom'] = '';
                                    }
                                } else {
                                    obj[key]['main']['manifoldLocation'] = '';
                                }
                            } else {
                                obj[key]['main']['manifold'] = '';
                            }

                            if (this.stepFour[key]['main']['displacementDistance'] === '') {
                                obj[key]['main']['displacementDistance'] = '';
                            } else {
                                if (this.stepFour[key]['main']['displacementDistance'] != 10 && this.stepFour[key]['main']['bathroom']) {
                                    if (this.stepFour[key]['main']['bathroomArea'] === '') {
                                        obj[key]['main']['bathroomArea'] = '';
                                    }
                                }
                            }

                            if (Object.keys(obj[key]['main']).length > 0) {
                                this.countErrorsStepFour += Object.keys(obj[key]['main']).length;
                            } else {
                                this.errors.stepFour[key] = {...this.errors.stepFour[key], ['main']: ""}
                            }
                        } else {
                            if (filter(valMain) === false) delete obj[key]['main'];
                        }

                        if (typeof valAdditional === "object" && key['additional'] !== 'tubeDiameters'
                            && key['additional'] !== 'tubes' && key['additional'] !== 'insulations') {
                            this.validateStepFourKeys(valAdditional, filter);

                            delete obj[key]['additional']['insulations']
                            delete obj[key]['additional']['insulation']
                            delete obj[key]['additional']['tubes']
                            delete obj[key]['additional']['tube']
                            delete obj[key]['additional']['tubeDiameter']
                            delete obj[key]['additional']['tubeDiameters']
                            delete obj[key]['additional']['screed']
                            delete obj[key]['additional']['screedValue']
                            delete obj[key]['additional']['displacementDistance']
                            delete obj[key]['additional']['manifoldLocation']
                            delete obj[key]['additional']['manifoldLocationCustom']
                            delete obj[key]['additional']['manifold']

                            if (this.stepThree[key]['additionalSystem']) {
                                if (this.stepThree[key]['main']['system'] !== ''
                                    && this.stepThree[key]['main']['subSystem'] !== ''
                                    && this.stepFour[key]['additional']['insulation'] === ''
                                    && this.stepFour[key]['additional']['insulations'].length > 0) {
                                    obj[key]['additional']['insulation'] = '';
                                }

                                if (this.stepFour[key]['additional']['tube'] !== '') {
                                    if (this.stepFour[key]['additional']['tubeDiameter'] === '') {
                                        obj[key]['additional']['tubeDiameter'] = '';
                                    }
                                } else {
                                    obj[key]['additional']['tube'] = '';
                                }

                                if (this.stepFour[key]['additional']['screed'] !== '') {
                                    if (this.stepFour[key]['additional']['screedValue'] === '') obj[key]['additional']['screedValue'] = '';
                                } else {
                                    obj[key]['additional']['screed'] = '';
                                }

                                if (this.stepFour[key]['additional']['manifold'] !== '') {
                                    if (this.stepFour[key]['additional']['manifoldLocation'] !== '') {
                                        if (this.stepFour[key]['additional']['manifoldLocation'] === 'custom_input' && this.stepFour[key]['additional']['manifoldLocationCustom'] === '') {
                                            obj[key]['additional']['manifoldLocationCustom'] = '';
                                        }
                                    } else {
                                        obj[key]['additional']['manifoldLocation'] = '';
                                    }
                                } else {
                                    obj[key]['additional']['manifold'] = '';
                                }

                                if (this.stepFour[key]['additional']['displacementDistance'] === '') obj[key]['additional']['displacementDistance'] = '';

                                if (Object.keys(obj[key]['additional']).length > 0) {
                                    this.countErrorsStepFour += Object.keys(obj[key]['additional']).length;
                                } else {
                                    this.errors.stepFour[key] = {...this.errors.stepFour[key], ['additional']: ""}
                                }
                            }
                        } else {
                            if (filter(valMain) === false) delete obj[key]['additional'];
                        }

                        if (JSON.stringify(valMain) === "{}") delete obj[key]['main'];

                        if (JSON.stringify(valAdditional) === "{}") delete obj[key]['additional'];
                    } else {
                        delete obj[key];
                    }
                }
            },

            // Check each step errors, almost on every click of everything :)
            checkFormSteps() {
                this.countErrorsStepOne = 0;
                this.countErrorsStepTwo = 0;
                this.countErrorsStepThree = 0;
                this.countErrorsStepFour = 0;
                this.countErrorsStepFourAdditional = 0;
                this.countErrorsStepFive = 0;

                this.errors = {
                    stepOne: {
                        'applicationType': this.stepOne.applicationTypeOption === '' && this.projectType === 'register',
                        'project': Object.fromEntries(
                            Object.entries(this.stepOne.project).filter(this.validateStepOneKeys(this.projectAddAddress))
                        ),
                        'installer': Object.fromEntries(
                            Object.entries(this.stepOne.installer).filter(this.validateStepOneKeys(this.installerAddAddress))
                        ),
                        'wholesale': Object.fromEntries(
                            Object.entries(this.stepOne.wholesale).filter(this.validateStepOneKeys(this.saleAddAddress))
                        ),
                    },
                    stepTwo: JSON.parse(JSON.stringify(this.stepTwo)),
                    stepThree: JSON.parse(JSON.stringify(this.stepThree)),
                    stepFour: JSON.parse(JSON.stringify(this.stepFour)),
                    stepFourAdditional: {
                        'maximumGroupLength': this.stepFourAdditional.maximumGroupLength === '',
                        'longPipe': this.stepFourAdditional.longPipe === ''
                    },
                    stepFive: {
                        'wireType': this.stepFive.wireType === '',
                        'heating': this.stepFive.heating.length === 0 && this.stepFive.wireType !== '' && this.stepFive.wireType !== 'n/a',
                        'spaceAdjustment': this.stepFive.spaceAdjustment === '' && this.stepFive.wireType !== '' && this.stepFive.wireType !== 'n/a',
                        'pipeCourse': this.stepFive.pipeCourse.length === 0 && (this.stepOne.applicationTypeOption === 'laying_plan' && this.projectType === 'register'),
                        'calculationCapacity': this.stepFive.calculationCapacity.length === 0 && (this.stepOne.applicationTypeOption === 'laying_plan' && this.projectType === 'register'),
                        'ventilationType': this.stepFive.ventilationType.length === 0 && (this.stepOne.applicationTypeOption === 'laying_plan' && this.projectType === 'register'),
                        'heatRecovery': (this.stepFive.heatRecovery === '' || !this.$v.stepFive.heatRecovery.between) && this.stepFive.ventilationType.includes('type_d'),
                    },
                };

                if (this.$v.stepOne.installer.phone.$invalid) this.errors.stepOne.installer.phone = '';
                if (this.$v.stepOne.installer.mail.$invalid) this.errors.stepOne.installer.mail = '';
                if (this.$v.stepOne.wholesale.phone.$invalid) this.errors.stepOne.wholesale.phone = '';
                if (this.$v.stepOne.wholesale.mail.$invalid) this.errors.stepOne.wholesale.mail = '';

                if (!this.stepOne.includingInstallation) {
                    this.stepOne.project.executionDate = '';

                    delete this.errors.stepOne.project.executionDate;
                }

                delete this.errors.stepOne.project.address
                delete this.errors.stepOne.installer.phone;
                delete this.errors.stepOne.wholesale.phone;

                let floorsArea = 0;

                if (!this.userRolesUnlimited.includes(this.currentUserRole) &&
                    this.floorsChanged && this.projectType === 'quote') {
                    for (let i = 1; i <= parseInt(this.floorsNum); i++) {
                        floorsArea += this.stepTwo['floor' + i].floorArea !== '' ? parseInt(this.stepTwo['floor' + i].floorArea) : 0;

                        this.maxArea = 200 - floorsArea;

                        if (this.stepTwo['floor' + i].floorArea === '') {
                            this.errors.stepTwo['floor' + i].floorArea = '';
                        } else {
                            delete this.errors.stepTwo['floor' + i].floorArea;
                        }

                        if ((this.maxArea < 0 || floorsArea > 200) || this.stepTwo['floor' + i].floorArea === '') {
                            this.errors.stepTwo['floor' + i].floorArea = '';
                        } else {
                            delete this.errors.stepTwo['floor' + i].floorArea;
                        }
                    }
                }

                this.validateStepTwoKeys(this.errors.stepTwo, (s) => s === '')

                this.validateStepThreeKeys(this.errors.stepThree, (s) => s === '')

                this.validateStepFourKeys(this.errors.stepFour, (s) => s === '')

                this.countErrorsStepOne = (this.errors.stepOne.applicationType == true ? 1 : 0)
                    + (Object.keys(this.errors.stepOne.project).length)
                    + (Object.keys(this.errors.stepOne.installer).length)
                    + (Object.keys(this.errors.stepOne.wholesale).length);

                this.countErrorsStepFourAdditional =
                    (this.errors.stepFourAdditional.maximumGroupLength == true ? 1 : 0)
                    + (this.errors.stepFourAdditional.longPipe == true ? 1 : 0);

                this.countErrorsStepFour += this.countErrorsStepFourAdditional;

                this.countErrorsStepFive = (this.errors.stepFive.wireType == true ? 1 : 0)
                    + (this.errors.stepFive.heating == true ? 1 : 0)
                    + (this.errors.stepFive.spaceAdjustment == true ? 1 : 0)
                    + (this.errors.stepFive.pipeCourse == true ? 1 : 0)
                    + (this.errors.stepFive.calculationCapacity == true ? 1 : 0)
                    + (this.errors.stepFive.ventilationType == true ? 1 : 0)
                    + (this.errors.stepFive.heatRecovery == true ? 1 : 0);

                if (this.stepFive.wireType === 'n/a') {
                    this.stepFive.heating = '';
                    this.stepFive.spaceAdjustment = '';
                }
            },

            // When you change the heat source
            changeTemp(floor, source, predefined = false) {
                const heatSource = this.heatSources.filter(el => el.id === source)[0];
                const heatOptions = heatSource ? JSON.parse(heatSource['heating_temp_options']) : null;
                const coolingOptions = heatSource ? JSON.parse(heatSource['cooling_temp_options']) : null;

                this.stepTwo[floor].coolingTemperatureOptions = '';
                this.stepTwo[floor].heatTemperatureOptions = '';

                if (predefined) {
                    if (heatOptions) {
                        if (Object.keys(heatOptions).length > 1) {
                            this.stepTwo[floor].heatTemperatureOptions = heatOptions;
                        } else {
                            this.stepTwo[floor].heatTemperature = heatOptions[0];
                        }
                    }

                    if (coolingOptions) {
                        if (Object.keys(coolingOptions).length > 1) {
                            this.stepTwo[floor].coolingTemperatureOptions = coolingOptions;
                        } else {
                            this.stepTwo[floor].coolingTemperature = coolingOptions[0];
                        }
                    }
                } else {
                    this.stepTwo[floor].heatTemperatureMin = '';
                    this.stepTwo[floor].heatTemperatureMax = '';

                    if (!heatSource || heatOptions === null) {
                        this.stepTwo[floor].heatTemperature = '';
                    } else if (Object.keys(heatOptions).length > 1) {
                        this.stepTwo[floor].heatTemperature = '';
                        this.stepTwo[floor].heatTemperatureOptions = heatOptions;
                    } else if (Object.keys(heatOptions).length === 1) {
                        this.stepTwo[floor].heatTemperature = heatOptions[0];
                    }

                    if (!heatSource || coolingOptions === null) {
                        this.stepTwo[floor].coolingTemperature = '';
                    } else if (Object.keys(coolingOptions).length > 1) {
                        this.stepTwo[floor].coolingTemperature = '';
                        this.stepTwo[floor].coolingTemperatureOptions = coolingOptions;
                    } else if (Object.keys(coolingOptions).length === 1) {
                        this.stepTwo[floor].coolingTemperature = coolingOptions[0];
                    }

                    this.checkFormSteps();
                }
            },

            // When you change the heat source temp
            changeTempValues(floor) {
                this.stepTwo[floor].heatTemperatureMin = '';
                this.stepTwo[floor].heatTemperatureMax = '';

                this.checkFormSteps();
            },

            // When you change the cooling temp
            changeCoolTemp(floor) {
                this.stepTwo[floor].coolingTemperatureMin = '';
                this.stepTwo[floor].coolingTemperatureMax = '';

                this.checkFormSteps();
            },

            // When you change the screed dropdown
            setDefaultScreedValue(floor, screed = null, type) {
                this.stepFour[floor][type].screedValue = '';

                if (screed) this.stepFour[floor][type].screedValue = this.screeds.filter(el => el.id == screed)[0].default_input;

                this.checkFormSteps();
            },

            // When you enter number of floors
            addFloors(floors) {
                if (this.projectType === 'quote' && floors > 2) {
                    alert(this.$gettext('Maximum 2 floors allowed'));

                    this.floorsNum = 1;
                } else if (isNaN(floors)) {
                    alert(this.$gettext('Please enter...'));
                } else {
                    for (let i = 0; i < floors; i++) {
                        this.stepTwo['floor' + (i + 1)] = {
                            cooling: '',
                            coolingTemperatureOptions: '',
                            coolingTemperature: '',
                            coolingTemperatureMin: '',
                            coolingTemperatureMax: '',
                            heatSource: '',
                            heatTemperature: '',
                            heatTemperatureOptions: '',
                            heatTemperatureMin: '',
                            heatTemperatureMax: '',
                            insulate: '',
                            floorFinish: '',
                            floorFinishCustom: '',
                            floorArea: ''
                        }

                        this.stepThree['floor' + (i + 1)] = {
                            main: {
                                system: '',
                                subSystems: '',
                                subSystem: '',
                                diameters: '',
                                tubeSpecification: '',
                            },
                            additional: {
                                system: '',
                                subSystems: '',
                                subSystem: '',
                                diameters: '',
                                tubeSpecification: '',
                            },
                            additionalSystem: false,
                        }

                        this.stepFour['floor' + (i + 1)] = {
                            main: {
                                insulations: '',
                                insulation: '',
                                manifold: '',
                                tubes: '',
                                tube: '',
                                tubeDiameters: '',
                                tubeDiameter: '',
                                displacementDistance: '',
                                bathroom: false,
                                bathroomArea: '',
                                screed: '',
                                screedValue: '',
                                manifoldLocation: '',
                            },
                            additional: {
                                insulations: '',
                                insulation: '',
                                manifold: '',
                                tubes: '',
                                tube: '',
                                tubeDiameters: '',
                                tubeDiameter: '',
                                displacementDistance: '',
                                screed: '',
                                screedValue: '',
                                manifoldLocation: '',
                            }
                        }
                    }

                    this.floorsChanged = true;
                    this.resetSteps = true;

                    this.checkFormSteps()
                }
            },

            // When you reset floors
            resetFloors() {
                this.resetSteps = false;
                this.floorsNum = 1;
                if (!this.userRolesUnlimited.includes(this.currentUserRole) && this.projectType === 'quote') this.maxArea = 200;

                this.stepTwo = {
                    floor1: {
                        cooling: '',
                        coolingTemperatureOptions: '',
                        coolingTemperature: '',
                        coolingTemperatureMin: '',
                        coolingTemperatureMax: '',
                        heatSource: '',
                        heatTemperature: '',
                        heatTemperatureOptions: '',
                        heatTemperatureMin: '',
                        heatTemperatureMax: '',
                        insulate: '',
                        floorFinish: '',
                        floorFinishCustom: '',
                        floorArea: ''
                    }
                };

                this.stepThree = {
                    floor1: {
                        main: {
                            system: '',
                            subSystems: '',
                            subSystem: '',
                            diameters: '',
                            tubeSpecification: '',
                        },
                        additional: {
                            system: '',
                            subSystems: '',
                            subSystem: '',
                            diameters: '',
                            tubeSpecification: '',
                        },
                        additionalSystem: false,
                    }
                };

                this.stepFour = {
                    floor1: {
                        main: {
                            insulations: '',
                            insulation: '',
                            manifold: '',
                            tubes: '',
                            tube: '',
                            tubeDiameters: '',
                            tubeDiameter: '',
                            displacementDistance: '',
                            bathroom: false,
                            bathroomArea: '',
                            screed: '',
                            screedValue: '',
                            manifoldLocation: '',
                        },
                        additional: {
                            insulations: '',
                            insulation: '',
                            manifold: '',
                            tubes: '',
                            tube: '',
                            tubeDiameters: '',
                            tubeDiameter: '',
                            displacementDistance: '',
                            screed: '',
                            screedValue: '',
                            manifoldLocation: '',
                        }
                    }
                };

                this.errorsCopy = {};

                this.checkFormSteps()

                this.floorsChanged = false;
            },

            // When you reset ventilation type
            resetHeatRecovery(ventilationType) {
                if (ventilationType === 'type_d') this.stepFive.heatRecovery = '';

                this.checkFormSteps();
            },

            // When you submit the selection form
            submitApplication() {
                this.isMaterialListChanged = false;
                this.isSubmitDisabled = true;

                axios.post(this.storeEndpoint, {
                    'variation': this.project.variation,
                    'version': this.project.version,
                    'editType': this.editType,
                    'projectType': this.projectType,
                    'projectId': this.project.id,
                    'stepOne': this.stepOne,
                    'stepTwo': this.stepTwo,
                    'stepThree': this.stepThree,
                    'stepFour': this.stepFour,
                    'stepFourAdditional': this.stepFourAdditional,
                    'stepFive': this.stepFive,
                    'services': this.selectedServices,
                }).then(response => {
                    this.project = {
                        'id': response.data[0].project_id,
                        'project_info_id': response.data[0].project_info_id,
                        'selection_id': response.data[0].selection_id,
                        'variation': response.data[0].selection.variation,
                        'version': response.data[0].selection.version,
                        'including_installation': response.data[0].selection.including_installation
                    }

                    const selection = response.data[0].selection;

                    if (this.projectType === 'quote' && (this.editType !== 'admin_data')) {
                        this.floorItems = Object.fromEntries(Object.entries(response.data).filter(([key]) => !key.includes('zone_control_items')));

                        if (response.data.zone_control_items) this.zoneControlItems = response.data.zone_control_items;

                        this.grandTotal = 0;
                        this.grandTotalItems = 0;
                        this.itemsFloorAreaTotal = 0;
                        this.itemsServices = 0;
                        const self = this;

                        for (const [key, floor] of Object.entries(this.floorItems)) {
                            if (key !== 'zone_control_items') {
                                switch (self.currentUserRole) {
                                    case('contractor-installer'):
                                        this.itemsSum[key] = floor.floor_prices.gross_price;
                                        break;
                                    default:
                                        this.itemsSum[key] = floor.floor_prices.net_price;
                                        break;
                                }

                                this.itemsQty[key] = Object.values(floor['items']).reduce(function(sum, item) {
                                    return sum + item.quantity;
                                }, 0)

                                this.grandTotal          += this.itemsSum[key];
                                this.grandTotalItems     += this.itemsSum[key];
                                this.itemsQuantityTotal  += this.itemsQty[key];
                                this.itemsFloorAreaTotal += floor['floor_area'];
                            }
                        }

                        if (this.zoneControlItems) {
                            this.itemsZone = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                                switch (self.currentUserRole) {
                                    case('contractor-installer'):
                                    case('wholesaler'):
                                        return sum + (item.quantity * item.gross_price);
                                    default:
                                        return sum + (item.quantity * item.net_price);
                                }
                            }, 0)

                            this.itemsZoneQty = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                                return sum + item.quantity;
                            }, 0)

                            this.grandTotal += this.itemsZone;
                            this.grandTotalItems += this.itemsZone;
                            this.itemsQuantityTotal += this.itemsZoneQty;
                        }

                        this.grandTotalItems = selection.including_installation ? selection.prices.net_materials : selection.prices.gross_materials;

                        switch (self.currentUserRole) {
                            case('contractor-installer'):
                                this.grandTotal = selection.including_installation ? selection.prices.net_price_installer : selection.prices.gross_price_installer;
                                break;
                            case('wholesaler'):
                                this.grandTotal = selection.including_installation ? selection.prices.net_price_wholesaler : selection.prices.gross_price_installer;
                                break;
                            default:
                                this.grandTotal = selection.including_installation ? selection.prices.net_final_price : selection.prices.gross_final_price;
                                break;
                        }

                        this.itemsServices = selection.prices.net_price_services;
                    } else {
                        this.goHome(5000);
                    }

                    this.showResults = true;
                })
            },

            // Group items in result by type
            groupItems(items) {
                return items.reduce(function (r, a) {
                    if (a.item) {
                        r[a.item.group] = r[a.item.group] || [];
                        r[a.item.group].push(a);
                    }

                    return r;
                }, Object.create(null));
            },

            // Sum group items
            groupSum(items) {
                switch (this.currentUserRole) {
                    case('contractor-installer'):
                    case('wholesaler'):
                        return {
                            'price': Object.values(items).reduce((sum, item) => sum + item['gross_price'], 0),
                            'total_qty': Object.values(items).reduce((sum, item) => sum + item['quantity'], 0)
                        }
                    default:
                        return {
                            'price': Object.values(items).reduce((sum, item) => sum + item['net_price'], 0),
                            'total_qty': Object.values(items).reduce((sum, item) => sum + item['quantity'], 0)
                        }
                }
            },

            // Format currency to NL
            formatCurrency(price) {
                return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(price);
            },

            // Get clicked results table item
            getItemInfo(item, floor = null) {
                this.selectedItem = item;
                this.selectedFloor = floor;
            },

            // Capitalize string
            capitalize(sentence) {
                return sentence.charAt(0).toUpperCase() + sentence.slice(1);
            },

            // If every step checkbox is checked for completion
            checkIfAllStepsCompleted() {
                this.allStepsCompleted = Object.values(this.stepSix).every(item => item);
            },

            // Heating type dynamic radio
            toggleAdjustments(type) {
                this.stepFive.heating = '';
                this.stepFive.zoneControlItems = {};

                switch(type) {
                    case 'wireless':
                        this.heating = {
                            '230v_heating': {
                                name: '230v_heating',
                                displayName: this.$gettext('230V heating')
                            },
                            'heating_and_cooling': {
                                name: 'heating_and_cooling',
                                displayName: this.$gettext('Heating and cooling')
                            },
                            'heating_and_cooling_switchover': {
                                name: 'heating_and_cooling_switchover',
                                displayName: this.$gettext('Heating and cooling switchover via thermostat')
                            }
                        }
                        break;
                    case 'wired':
                        this.heating = {
                            '24v_heating': {
                                name: '24v_heating',
                                displayName: this.$gettext('24V heating')
                            },
                            '24v_heating_and_cooling': {
                                name: '24v_heating_and_cooling',
                                displayName: this.$gettext('24V heating and cooling')
                            },
                            '230v_heating': {
                                name: '230v_heating',
                                displayName: this.$gettext('230V heating')
                            },
                            '230v_heating_and_cooling': {
                                name: '230v_heating_and_cooling',
                                displayName: this.$gettext('230V heating and cooling')
                            }
                        }
                        break;
                    case 'simple_engine':
                        this.heating = {
                            '24v_heating': {
                                name: '24v_heating',
                                displayName: this.$gettext('24V heating')
                            },
                            '230v_heating': {
                                name: '230v_heating',
                                displayName: this.$gettext('230V heating')
                            },
                        }
                        break;
                    default:
                        this.heating = {};
                        break;
                }
            },

            // Request to fetch zone control items from DB
            getZoneItems(predefinedItems = null) {
                this.stepFive.zoneControlItems = {};

                axios.get(this.zoneControlEndpoint, {
                    params: {
                        'wire_type': this.stepFive.wireType,
                        'heating': this.stepFive.heating
                    }
                }).then(response => {
                    if (response.data) {
                        this.stepFive.zoneControlItems = response.data;

                        if (predefinedItems) {
                            let self = this;

                            for (let i = 0; i < predefinedItems.length; i++) {
                                Object.values(self.stepFive.zoneControlItems).map(el => {
                                    if (el.sku === predefinedItems[i].item.sku) el.quantity = predefinedItems[i].quantity;
                                })
                            }
                        }
                    }
                })

                this.checkFormSteps();
            },

            // Check if zero is typed into input and transform it to one for zone control/currently inactive
            checkZero(step, model, index, key) {
                if (this[step][model][index][key] === 0) this[step][model][index][key] = 1;
            },

            // Check if zero is typed into input and transform it to one for material list
            checkZeroItem(model) {
                if (parseInt(this[model].qty) === 0) this[model].qty = 1;
            },

            // Autocomplete inputs
            getWholesalers(id = null, initial = false, predefined = false) {
                axios.get(this.wholesalersEndpoint, {
                    params: {
                        installerId: id,
                    }
                }).then(response => {
                    this.wholesalers = response.data;

                    if (this.wholesalers.length === 0 && predefined) {
                        this.installerNotFound = true;
                        this.showResults = true;

                        this.goHome(5000)
                    }

                    if (this.wholesalers.length === 0 && initial) {
                        this.noMarginDetected = true;
                        this.showResults = true;

                        this.goHome(5000)
                    }
                })
            },

            // Autocomplete inputs
            getInstallers(id = null, initial = false, predefined = false) {
                axios.get(this.installersEndpoint, {
                    params: {
                        wholesalerId: id,
                    }
                }).then(response => {
                    this.installers = response.data;

                    if (this.installers.length === 0 && predefined) {
                        this.wholesalerNotFound = true;
                        this.showResults = true;

                        this.goHome(5000)
                    }

                    if (this.installers.length === 0 && initial) {
                        this.noMarginDetected = true;
                        this.showResults = true;

                        this.goHome(5000)
                    }
                })
            },

            setContact(index, type) {
                if (type === 'installer') {
                    this.stepOne.installer.contact = this.installer.users[index].full_name;
                    this.stepOne.installer.phone = this.installer.users[index].phone ? this.installer.users[index].phone.replace(/\s/g, '') : '';
                    this.stepOne.installer.mail = this.installer.users[index].email.toLowerCase();
                } else {
                    this.stepOne.wholesale.contact = this.wholesaler.users[index].full_name;
                    this.stepOne.wholesale.phone = this.wholesaler.users[index].phone ? this.wholesaler.users[index].phone.replace(/\s/g, '') : '';
                    this.stepOne.wholesale.mail = this.wholesaler.users[index].email.toLowerCase();
                }

                this.checkFormSteps();
            },

            // When selected autocomplete for wholesaler/installer
            saveChoice(type, clear = null, initial = null, info = null) {
                this.installerUsers = {};
                this.wholesalerUsers = {};

                if (clear === null) {
                    if (type === 'installers') {
                        this.stepOne.installer.name = this.installer ? this.installer.name : '';
                        this.stepOne.installer.address = this.installer ? this.installer.address : '';
                        this.stepOne.installer.postcode = this.installer ? this.installer.postcode : '';
                        this.stepOne.installer.city = this.installer ? this.installer.city : '';
                        this.stepOne.installer.contact = '';
                        this.stepOne.installer.phone = '';
                        this.stepOne.installer.mail = '';
                        this.installerUsers = {};

                        if (this.installer) {
                            if (this.installer && Object.keys(this.installer.users).length > 0) {
                                this.stepOne.installer.contact = this.installer.users[0].full_name;
                                this.stepOne.installer.phone = this.installer.users[0].phone ? this.installer.users[0].phone.replaceAll(' ', '') : '';
                                this.stepOne.installer.mail = this.installer.users[0].email.toLowerCase();
                            }

                            if (Object.keys(this.installer.users).length > 1) this.installerUsers = this.installer.users;

                            this.getWholesalers(this.installer.id, true)
                        } else {
                            this.getWholesalers();
                        }
                    }

                    if (type === 'wholesalers') {
                        this.stepOne.wholesale.name = this.wholesaler ? this.wholesaler.name : '';
                        this.stepOne.wholesale.address = this.wholesaler ? this.wholesaler.address : '';
                        this.stepOne.wholesale.postcode = this.wholesaler ? this.wholesaler.postcode : '';
                        this.stepOne.wholesale.city = this.wholesaler ? this.wholesaler.city : '';
                        this.stepOne.wholesale.contact = '';
                        this.stepOne.wholesale.phone = '';
                        this.stepOne.wholesale.mail = '';
                        this.wholesalerUsers = {};

                        if (this.wholesaler) {
                            if (Object.keys(this.wholesaler.users).length > 0) {
                                this.stepOne.wholesale.contact = this.wholesaler.users[0].full_name;
                                this.stepOne.wholesale.phone = this.wholesaler.users[0].phone ? this.wholesaler.users[0].phone.replaceAll(' ', '') : '';
                                this.stepOne.wholesale.mail = this.wholesaler.users[0].email.toLowerCase();
                            }

                            if (this.wholesaler.users && Object.keys(this.wholesaler.users).length > 1) this.wholesalerUsers = this.wholesaler.users;

                            this.getInstallers(this.wholesaler.id, true)
                        } else {
                            this.getInstallers();
                        }
                    }
                } else {
                    if (type === 'installers') {
                        this.stepOne.installer.name = '';
                        this.stepOne.installer.address = '';
                        this.stepOne.installer.postcode = '';
                        this.stepOne.installer.city = '';
                        this.stepOne.installer.contact = '';
                        this.stepOne.installer.phone = '';
                        this.stepOne.installer.mail = '';

                        this.getWholesalers()
                    }

                    if (type === 'wholesalers') {
                        this.stepOne.wholesale.name = '';
                        this.stepOne.wholesale.address = '';
                        this.stepOne.wholesale.postcode = '';
                        this.stepOne.wholesale.city = '';
                        this.stepOne.wholesale.contact = '';
                        this.stepOne.wholesale.phone = '';
                        this.stepOne.wholesale.mail = '';

                        this.getInstallers()
                    }
                }

                this.checkFormSteps();
            },

            // Upload file function for step 5
            uploadFile(e, step, ref) {
                const files = e.target.files;
                this[step][ref] = [];
                this.errors.stepFive.docs = [];

                for (let i = 0; i < files.length; i++) {
                    this.upload(i, files[i], step, ref);
                }
            },

            // After uploadFile
            upload(i, file, step, ref) {
                const reader = new FileReader();
                const fileTypes = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'dwg'];
                this.$forceUpdate();

                if (file && file.name) {
                    const extension = file.name.split('.').pop().toLowerCase(),  //file extension from input file
                        isSuccess = fileTypes.indexOf(extension) > -1;

                    if (isSuccess) {
                        reader.readAsDataURL(file);

                        reader.onload = e => {
                            this[step][ref][i] = {
                                'file': e.target.result,
                                'originalName': file.name
                            }
                        };
                    } else {
                        this.errors[step][ref].push(file.name);
                    }
                } else {
                    this.errors[step][ref].push(file.name);
                }
            },

            // On hover show overlay of product image
            showImageOverlay(itemId) {
                this.showItemId = itemId;
                this.showImage = true;
            },

            // Manual upload of PRI file
            uploadPRI(file) {
                const reader = new FileReader();
                const uploadedFile = file.target.files[0];
                this.$forceUpdate();

                if (uploadedFile && uploadedFile.name) {
                    reader.readAsDataURL(uploadedFile);

                    reader.onload = e => {
                        axios.post(this.priUploadEndpoint, {
                            pri: e.target.result
                        }).then(response => {
                            const info = response.data;
                            const exDate = Date.parse(info.project.GEZD) ?? null;

                            this.stepOne.project.executionDate = exDate ? moment(exDate).format("YYYY-DD-MM") : '';
                            this.stepOne.project.name = info.project['PR1'];
                            this.stepOne.project.city = info.project['ORT'];
                            this.stepOne.project.postcode = info.project['PLZ'];

                            if (info.project['PRNR']) this.stepOne.project.number = info.project['PRNR'].substring(4, info.project['PRNR'].indexOf(' '));

                            this.checkProjectNumber();

                            this.stepOne.project.address = info.project['STR'];

                            const installer = Object.keys(this.installers.filter(el => el.name === info.installer['NAME1'])).length
                                ? this.installers.filter(el => el.name === info.installer['NAME1'])[0] : '';

                            this.stepOne.installer.name = installer.name ?? '';
                            this.stepOne.installer.address = info.installer['STR'];
                            this.stepOne.installer.postcode = info.installer['PLZ'];
                            this.stepOne.installer.city = info.installer['ORT'];
                            this.stepOne.installer.phone = info.installer['TEL'] ?? '';

                            if (installer) {
                                this.installer = installer;
                                this.getWholesalers(installer.id)
                            } else {
                                alert(this.$gettext('Installer not found!'));
                            }

                            const wholesaler = Object.keys(this.wholesalers.filter(el => el.name === info.wholesaler['NAME1'])).length ?
                                this.wholesalers.filter(el => el.name === info.wholesaler['NAME1'])[0] : '';

                            this.stepOne.wholesale.name = wholesaler.name ?? '';
                            this.stepOne.wholesale.address = info.wholesaler['STR'];
                            this.stepOne.wholesale.postcode = info.wholesaler['PLZ'];
                            this.stepOne.wholesale.city = info.wholesaler['ORT'];
                            this.stepOne.wholesale.phone = info.wholesaler['TEL'] ?? '';

                            if (wholesaler) {
                                this.wholesaler = wholesaler;
                                this.getInstallers(wholesaler.id)
                            } else {
                                alert(this.$gettext('Wholesaler not found!'));
                            }

                            this.checkFormSteps();
                        })
                    };
                }
            },

            calculateItemList(floors, zoneItems, prices, includingInstallation) {
                this.floorItems = Object.fromEntries(Object.entries(floors));

                let priceToCalculate = this.stepOne.includingInstallation ? 'gross_price' : 'net_price';

                for (const [key, items] of Object.entries(floors)) {
                    this.itemsSum[key] = Object.values(items['items']).reduce(function (sum, item) {
                        return sum + item[priceToCalculate];
                    }, 0)

                    this.itemsQty[key] = Object.values(items['items']).reduce(function (sum, item) {
                        return sum + item.quantity;
                    }, 0)

                    this.grandTotalItems += this.itemsSum[key];
                    this.itemsQuantityTotal += this.itemsQty[key];
                    this.itemsFloorAreaTotal += items['floor_area'];
                }

                if (zoneItems) this.zoneControlItems = zoneItems;

                if (this.zoneControlItems) {
                    this.itemsZone = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                        return sum + (item.quantity * item.net_price);
                    }, 0)

                    this.itemsZoneQty = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                        return sum + item.quantity;
                    }, 0)

                    this.grandTotalItems += this.itemsZone;
                    this.itemsQuantityTotal += this.itemsZoneQty;
                }

                this.grandTotal = includingInstallation ? prices.net_final_price : prices.gross_final_price;
            },

            // On choosing new item to material list
            setItemParams(floor) {
                this.itemParams = {
                    itemGroup: '',
                    itemFloor: floor
                };

                this.listOfItemsByGroup = {};

                this.selectedNewItem = {
                    item: {},
                    qty: 1,
                };
            },

            // Load all items from selected group when adding a new item to material list
            getItemsByGroup() {
                this.listOfItemsByGroup = {};

                this.selectedNewItem = {
                    item: {},
                    qty: 1,
                };

                if (this.itemParams.itemGroup !== '') {
                    axios.get(this.itemsByGroupEndpoint, {
                        params: {
                            itemParams: this.itemParams
                        }
                    }).then(res => {
                        this.listOfItemsByGroup = res.data;
                    });
                }
            },

            // Request to get data for selected new item to material list
            addItemToList() {
                axios.post(this.addItemToListEndpoint, {
                    floor: this.itemParams.itemFloor,
                    item: this.selectedNewItem.item.sku,
                    quantity: this.selectedNewItem.qty,
                    project: this.project
                }).then(res => {
                    let floorItems = this.floorItems[this.itemParams.itemFloor - 1]['items'];

                    if (floorItems.find(el => el.item.sku === this.selectedNewItem.item.sku)) {
                        floorItems.find(el => el.item.sku === this.selectedNewItem.item.sku).quantity += parseInt(this.selectedNewItem.qty);
                    } else {
                        floorItems.push(res.data);
                    }

                    this.grandTotal += res.data['net_price'];

                    this.itemParams = {
                        itemGroup: '',
                        itemFloor: 1
                    }

                    this.selectedNewItem = {
                        item: {},
                        qty: 1,
                        floor_id: 1
                    }

                    this.listOfItemsByGroup = {};

                    this.updateItemList();

                    $('#addItemModal').modal('hide');
                })
            },

            // In material list when updating item qty
            updateItemQty(item) {
                if (this.selectedItemUpdate.qty !== '') {
                    item['gross_price'] = (item['gross_price'] / item['quantity']) * parseInt(this.selectedItemUpdate.qty);
                    item['net_price'] = (item['net_price'] / item['quantity']) * parseInt(this.selectedItemUpdate.qty);
                    item['quantity'] = parseInt(this.selectedItemUpdate.qty);

                    this.grandTotal += item['net_price'];

                    this.updateItemList();

                    $('#itemModal').modal('hide');
                } else {
                    alert(this.$gettext('Please enter...'))
                }
            },

            // Recalculate items to material list
            updateItemList() {
                this.grandTotalItems = 0;
                this.itemsQuantityTotal = 0;
                this.itemsFloorAreaTotal = 0;
                this.itemsZone = 0;
                this.itemsZoneQty = 0;

                for (const [key, items] of Object.entries(this.floorItems)) {
                    this.itemsSum[key] = 0;
                    this.itemsQty[key] = 0;

                    if (key !== 'zone_control_items') {
                        switch (self.currentUserRole) {
                            case 'contractor-installer':
                                this.itemsSum[key] = Object.values(items['items']).reduce(function (sum, item) {
                                    return sum + item.gross_price;
                                }, 0)
                                break;
                            case 'wholesaler':
                                if (this.selection.including_installation) {
                                    this.itemsSum[key] = Object.values(items['items']).reduce(function (sum, item) {
                                        return sum + item.net_price;
                                    }, 0)
                                } else {
                                    this.itemsSum[key] = Object.values(items['items']).reduce(function (sum, item) {
                                        return sum + item.gross_price;
                                    }, 0)
                                }
                                break;
                            default:
                                this.itemsSum[key] = Object.values(items['items']).reduce(function (sum, item) {
                                    return sum + item.net_price;
                                }, 0)
                                break;
                        }

                        this.itemsQty[key] = Object.values(items['items']).reduce(function(sum, item) {
                            return sum + item.quantity;
                        }, 0)

                        this.grandTotalItems += this.itemsSum[key];
                        this.itemsQuantityTotal += this.itemsQty[key];
                        this.itemsFloorAreaTotal += items['floor_area'];
                    }
                }

                if (this.zoneControlItems) {
                    switch (this.currentUserRole) {
                        case 'contractor-installer':
                            this.itemsZone = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                                return sum + (item.quantity * item.gross_price);
                            }, 0)
                            break;
                        case 'wholesaler':
                            if (this.selection.including_installation) {
                                this.itemsZone = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                                    return sum + (item.quantity * item.net_price);
                                }, 0)
                            } else {
                                this.itemsZone = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                                    return sum + (item.quantity * item.gross_price);
                                }, 0)
                            }
                            break;
                        default:
                            this.itemsZone = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                                return sum + (item.quantity * item.net_price);
                            }, 0)
                            break;
                    }

                    this.itemsZoneQty = Object.values(this.zoneControlItems).reduce(function(sum, item) {
                        return sum + item.quantity;
                    }, 0)

                    this.grandTotalItems += this.itemsZone;
                    this.itemsQuantityTotal += this.itemsZoneQty;
                }

                this.isMaterialListChanged = true;
            },

            // Delete item from material list
            deleteItem(item) {
                this.floorItems[this.selectedFloor - 1]['items'] = this.floorItems[this.selectedFloor - 1]['items'].filter(el => el.id !== item.id);

                this.grandTotal -= item.net_price;

                this.updateItemList();

                $('#itemModalDelete').modal('hide');
            },

            // Save config items
            saveProject() {
                axios.post(this.saveMaterialListEndpoint, {
                    'floor_items': this.floorItems,
                    'project': this.project,
                    'zone_control_items': this.zoneControlItems,
                    'editType': this.editType,
                    'services': this.selectedServices,
                }).then(res => {
                    if (res.status === 200) {
                        this.isProjectSaved = true;

                        this.goHome(5000);
                    }
                });
            },

            checkDistance(index, distance) {
                this.currentFloor = {
                    floor: index,
                    bathroom: this.stepFour['floor' + index].main.bathroom,
                }

                if (!distance || distance == 10) {
                    this.stepFour['floor' + index].main.bathroom = false;
                    this.stepFour['floor' + index].main.bathroomArea = '';
                } else {
                    $('#bathroomModal').modal('show')
                }
            },

            updateBathroom(confirm) {
                this.stepFour['floor' + this.currentFloor.floor].main.bathroom = confirm;

                if (confirm) {
                    this.stepFour['floor' + this.currentFloor.floor].main.bathroomArea = '';
                }

                this.checkFormSteps();
            },

            goHome(delay = 0) {
                if (sessionStorage.getItem('selection-data')) sessionStorage.removeItem('selection-data');

                this.timerCount = delay > 0 ? delay / 1000 : 0;

                window.setTimeout(() => {
                    window.location.href = this.mainEndpoint;
                }, delay)
            },

            checkProjectNumber: _.debounce(function () {
                this.usedProjectNumber = null;

                if (this.stepOne.project.number !== '') {
                    axios.post(this.checkProjectNumberEndpoint, {
                        project_number: parseInt(this.stepOne.project.number),
                        selection_id: this.project.selection_id,
                        editType: this.editType
                    }).then(() => {}).catch(error => {
                        if (error.response) {
                            this.errors.stepOne.project.number = '';
                            this.usedProjectNumber = error.response.data.message;
                            this.countErrorsStepOne += 1;
                        }
                    })
                }
            }, 500),

            createNewVersion() {
                this.editType = 'whole_project';

                this.getProjectNumber();

                this.isSubmitDisabled = false;
            },

            getServices(selection_id = null) {
                axios.get(this.servicesEndpoint, {
                    params: {
                        predefined: !!selection_id,
                        selection_id: selection_id,
                    }
                }).then(res => {
                    this.services = res.data.allServices;

                    if (selection_id) {
                        this.selectedServices = res.data.selectedServices;

                        this.services.forEach(el => {
                            el.active_children.forEach(elem => {
                                if (this.selectedServices.includes(elem.id)) {
                                    this.itemsServices += elem.net_price;
                                }
                            })
                        })
                    } else {
                        this.services.forEach(el => {
                            el.active_children.forEach(child => {
                                if (child.required) this.selectedServices.push(child.id)
                            })

                            if (el.required && el.active_children.filter(el => el.required).length == 0) {
                                this.selectedServices.push(el.active_children[0].id)
                            }
                        })
                    }
                });
            },

            updateServices() {
                this.selectedServices = [];

                this.$refs.services.filter(el => el.checked).forEach(el => {
                    this.selectedServices.push(parseInt(el.value))
                })
            }
        },

        validations: {
            stepOne: {
                installer: {
                    mail: { email },
                    phone: {
                        minLength: minLength(8),
                        maxLength: maxLength(15),
                    },
                },
                wholesale: {
                    mail: { email },
                    phone: {
                        minLength: minLength(8),
                        maxLength: maxLength(15),
                    }
                },
            },
            stepFive: {
                heatRecovery: {
                    between: between(0, 100)
                }
            },
        },
    }
</script>

<style>
.v-input {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.v-menu {
    position: relative;
    z-index: 2;
    background: #fff;
    transform: none;
}

.v-application.color-henco-red .v-input {
    border-color: #e42313 !important;
}

.v-select__slot {
    display: flex;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
}

.v-select__slot .v-icon.v-icon--link {
    border: none;
    background: transparent;
}

.v-select__slot input {
    border: none;
    outline: none;
    width: 100%;
    background: #fff;
    color: #000;
}

.v-input__control {
    background: #fff;
    z-index: 2;
    width: 100%;
}

.v-autocomplete__content {
    height: 200px;
    overflow: auto;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 10px;
}

.v-autocomplete__content .v-list-item {
    margin-bottom: 10px;
    cursor: pointer;
}

.v-select__slot {
    position: relative;
    align-items: center;
    display: flex;
    max-width: 100%;
    min-width: 0;
    width: 100%;
}

.border-danger .v-select__slot {
    border: 1px solid red;
}

.v-select.v-select--chips .v-select__selections {
    min-height: 35px;
}

.theme--light.v-chip:not(.v-chip--active) {
    background: #e0e0e0;
}

.v-select__selections {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    line-height: 18px;
    max-width: 100%;
    min-width: 0;
}

.v-chip .v-chip__content {
    align-items: center;
    display: inline-flex;
    height: 100%;
    max-width: 100%;
}

.v-chip {
    align-items: center;
    cursor: default;
    display: inline-flex;
    line-height: 20px;
    max-width: 100%;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-decoration: none;
    transition-duration: .28s;
    transition-property: box-shadow, opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    vertical-align: middle;
    white-space: nowrap;
}

.v-chip.v-size--default {
    border-radius: 16px;
    font-size: 14px;
    height: 32px;
    margin: 4px;
}

.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right {
    margin-right: -4px;
}
</style>
