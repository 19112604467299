<script>

export default {
    name: 'tenant',

    props: [
        'countriesEndpoint',
        'createTenantEndpoint',
        'destroyTenantEndpoint',
    ],

    data() {
        return {
            countries: [],
            newTenant: {
                country: '',
                name: ''
            },
            loader: false,
            currentTenant: null,
        }
    },

    mounted() {
        this.getCountries();
    },

    methods: {
        getCountries() {
            axios.get(this.countriesEndpoint).then(res => {
                this.countries = res.data;
            })
        },

        createTenant() {
            this.loader = true;

            axios.post(this.createTenantEndpoint, {
                'tenant': this.newTenant
            }).then(res => {
                if (res.status == 200) {
                    this.loader = false;

                    window.location.reload();
                }
            })
        },

        passTenantData(id) {
            this.currentTenant = id;
        },

        deleteTenant() {
            axios.delete(this.destroyTenantEndpoint, {
                data: {
                    id: this.currentTenant
                }
            }).then(res => {
                if (res.status == 200) {
                    $("#deleteTenantModal").modal('hide');

                    window.location.reload();
                }
            })
        }
    }
}
</script>
