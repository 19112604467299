<template>
    <div>
        <div class="text-center text-md-right">
            <button class="btn color-henco-blue border-henco-blue" data-toggle="modal" data-target="#addExecutionPartner">
                <i class="fa fa-plus"></i>
                <span v-translate>Add execution partner</span>
            </button>
        </div>

        <div class="modal fade bd-example-modal-lg" id="addExecutionPartner" tabindex="-1" role="dialog" aria-labelledby="addExecutionPartner" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-henco-blue">
                        <h5 class="modal-title text-white" id="addExecutionPartnerTitle" v-translate>Add execution partner</h5>
                        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form method="POST" @submit.prevent="addExecutionPartner()">
                            <div class="form-group text-left">
                                <label class="col-form-labe color-henco-bluel" for="company"
                                       v-text="$gettext('Company') + ': *'"></label>
                                <input type="text" class="form-control color-henco-blue" id="company" name="company" required
                                       v-model="company" :placeholder="$gettext('Enter company...')"/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.company" v-for="(name, index) in errors.company">
                                    <strong v-text="errors.company[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="contact_person"
                                       v-text="$gettext('Contact person') + ': *'"></label>
                                <input type="text" class="form-control color-henco-blue" id="contact_person" name="contact_person" required
                                       v-model="contact_person" :placeholder="$gettext('Enter contact person...')"/>

                                <span class="alert-danger bg-transparent mb-1" role="alert" v-if="errors.contact_person"
                                      v-for="(name, index) in errors.contact_person">
                                    <strong v-text="errors.contact_person[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="email_planning"
                                       v-text="$gettext('Planning e-mail') + ':'"></label>
                                <input type="email" class="form-control color-henco-blue" id="email_planning" name="email_planning"
                                       v-model="email_planning" :placeholder="$gettext('Enter planning email...')"/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.email_planning" v-for="(name, index) in errors.email_planning">
                                    <strong v-text="errors.email_planning[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="email_execution"
                                       v-text="$gettext('Execution e-mail') + ':'"></label>
                                <input type="email" class="form-control color-henco-blue" id="email_execution" name="email_execution"
                                       v-model="email_execution" :placeholder="$gettext('Enter execution email...')"/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.email_execution" v-for="(name, index) in errors.email_execution">
                                    <strong v-text="errors.email_execution[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="email_administration"
                                       v-text="$gettext('Administrative e-mail') + ':'"></label>
                                <input type="email" class="form-control color-henco-blue" id="email_administration" name="email_administration"
                                       v-model="email_administration" :placeholder="$gettext('Enter administrative email...')"/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.email_administration" v-for="(name, index) in errors.email_administration">
                                    <strong v-text="errors.email_administration[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="phone" v-text="$gettext('Phone number') + ':'"></label>
                                <input type="text" class="form-control color-henco-blue" id="phone" name="phone"
                                       v-model="phone" :placeholder="$gettext('Enter phone number...')"/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.phone" v-for="(name, index) in errors.phone">
                                    <strong v-text="errors.phone[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-right mb-0">
                                <button type="submit" class="btn bg-henco-blue text-white" v-text="$gettext('Add')"></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'add-execution-partner',

        props: [
            'storeEndpoint'
        ],

        data() {
            return {
                company: '',
                contact_person: '',
                email_planning: '',
                email_execution: '',
                email_administration: '',
                phone: '',
                errors: []
            }
        },

        methods: {
            addExecutionPartner() {
                this.errors = [];

                axios.post(this.storeEndpoint, {
                    'company': this.company,
                    'contact_person': this.contact_person,
                    'email_planning': this.email_planning,
                    'email_execution': this.email_execution,
                    'email_administration': this.email_administration,
                    'phone': this.phone
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        $("#addExecutionPartner").modal('hide');

                        window.location.reload();
                    }
                });
            },
        },
    };
</script>
