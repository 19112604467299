<template>
    <button type="button" @click="click(data)" ref="content" class="btn bg-henco-blue text-white">
        <i class="fas fa-sign-in-alt"></i>
    </button>
</template>

<script>
export default {
    props: {
        data: {},
        name: {},
        click: {},
        meta: {},
        classes: {},
    },
}
</script>
