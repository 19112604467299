<script>
    import {DataTable} from 'laravel-vue-datatable';
    import ActionDataTableButtons from "./ActionDataTableButtons";

    export default {
        name: 'execution-partners-table',

        props: [
            'executionPartnersEndpoint',
            'editExecutionPartnerEndpoint',
            'deleteExecutionPartnerEndpoint'
        ],

        components: { DataTable, ActionDataTableButtons },

        data() {
            return {
                executionPartners: {},
                columns: [
                    {
                        name: "company",
                        label: this.$gettext('Company'),
                        orderable: true
                    },
                    {
                        name: "contact_person",
                        label: this.$gettext('Contact person'),
                        orderable: true
                    },
                    {
                        name: "phone",
                        label: this.$gettext('Phone number'),
                        orderable: true
                    },
                    {
                        meta: {0: 'execution_partners'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search execution partners'),
                },
                rowData: {},
                installers: [],
                editData: {
                    id: '',
                    guid: '',
                    name: '',
                    address: '',
                    postcode: '',
                    city: ''
                },
                errors: [],
                modalLoader: true
            }
        },

        mounted() {
            this.getExecutionPartners();
        },

        methods: {
            getExecutionPartners(url = this.executionPartnersEndpoint, options = this.tableProps) {
                axios.get(url, {
                    params: {
                        search: options.search,
                        length: options.length,
                        column: options.column,
                        dir: options.dir,
                        page: options.page
                    }
                }).then(response => {
                    this.executionPartners = response.data;
                })
            },

            reloadTable(tableProps) {
                this.getExecutionPartners(this.executionPartnersEndpoint, tableProps);
            },

            edit() {
                this.errors = [];

                axios.post(this.editExecutionPartnerEndpoint, {
                    'data': this.editData
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        $("#itemModalEdit").modal('hide');

                        window.location.reload();
                    }
                })
            },

            deleteRow(id) {
                axios.post(this.deleteExecutionPartnerEndpoint, {id}).then(() => {
                    $("#itemModalDelete").modal('hide');

                    window.location.reload();
                })
            },

            getRowData(actionName, data) {
                this.errors = [];

                this.rowData = Object.assign({}, data);
                this.editData = Object.assign({}, data);
            }
        }
    }
</script>
