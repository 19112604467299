<script>
    import {DataTable} from 'laravel-vue-datatable';
    import ActionDataTableButtons from "./ActionDataTableButtons";

    export default {
        name: 'connections-table',

        props: [
            'manifoldEndpoint',
            'screedEndpoint',
            'tubeEndpoint',
            'storeScreedEndpoint',
            'storeManifoldEndpoint',
            'storeTubeEndpoint',
            'updateSystemsEndpoint',
            'destroyConnectionEndpoint'
        ],

        components: { DataTable, ActionDataTableButtons },

        data() {
            return {
                screed: {
                    name: '',
                    default_input: '',
                },
                manifold: {
                    name: '',
                    cooling: '',
                    tube_specification: '',
                },
                tube: {
                    name: '',
                    diameters: '',
                },
                columnsScreed: [
                    {
                        name: "name_formatted",
                        columnName: "display_name",
                        label: this.$gettext('Name')
                    },
                    {
                        name: "default_input",
                        label: this.$gettext('Default input'),
                        orderable: true,
                    },
                    {
                        meta: {0: 'screed'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    }
                ],
                columnsManifold: [
                    {
                        name: "name_formatted",
                        columnName: "display_name",
                        label: this.$gettext('Name')
                    },
                    {
                        name: "cooling_text",
                        label: this.$gettext('Cooling'),
                    },
                    {
                        name: "tube_specification",
                        label: this.$gettext('Tube specification'),
                        orderable: true,
                    },
                    {
                        meta: {0: 'manifold'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    }
                ],
                columnsTube: [
                    {
                        name: "name_formatted",
                        columnName: "display_name",
                        label: this.$gettext('Name')
                    },
                    {
                        name: "diameters_formatted_table",
                        label: this.$gettext('Diameters'),
                    },
                    {
                        meta: {0: 'tube'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    }
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search connections'),
                },
                errors: [],
                rowData: {},
                editData: {}
            }
        },

        methods: {
            addScreed() {
                this.errors = [];

                axios.post(this.storeScreedEndpoint, {
                    'name': this.screed.name,
                    'default_input': this.screed.default_input
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addScreed").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            addManifold() {
                this.errors = [];

                axios.post(this.storeManifoldEndpoint, {
                    'name': this.manifold.name,
                    'cooling': this.manifold.cooling,
                    'tube_specification': this.manifold.tube_specification
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addManifold").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            addTube() {
                this.errors = [];

                axios.post(this.storeTubeEndpoint, {
                    'name': this.tube.name,
                    'diameters': this.tube.diameters
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addTube").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            editItem() {
                this.errors = [];

                axios.post(this.updateSystemsEndpoint, {
                    'data': this.editData
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#itemModalEdit").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            deleteItem(id, type) {
                axios.post(this.destroyConnectionEndpoint, {
                    id, type
                }).then(response => {
                    if (response.status == 200) {
                        $("#itemModalDelete").modal('hide');

                        window.location.reload();
                    }
                })
            },

            getRowData(actionName, data, meta) {
                this.errors = [];

                this.rowData = Object.assign({}, data);
                this.rowData.type = meta[0];

                this.editData = {
                    'id': data.id,
                    'name': data.name,
                    'display_name': data.display_name,
                    'default_input': data.default_input ?? '',
                    'cooling': data.cooling ?? '',
                    'tube_specification': data.tube_specification ?? '',
                    'diameters': data.diameters_formatted ? data.diameters_formatted.replace('[', '').replace(']', '') : '',
                    'type': meta[0],
                }
            },

            resetOptions() {
                this.errors = [];

                this.screed = {
                    name: '',
                    default_input: '',
                };

                this.manifold = {
                    name: '',
                    cooling: '',
                    tube_specification: '',
                };

                this.tube = {
                    name: '',
                    diameters: '',
                };
            }
        }
    }
</script>
