var render, staticRenderFns
import script from "./EmailTemplatesTable.vue?vue&type=script&lang=js&"
export * from "./EmailTemplatesTable.vue?vue&type=script&lang=js&"
import style0 from "./EmailTemplatesTable.vue?vue&type=style&index=0&id=511595b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511595b2",
  null
  
)

export default component.exports