<script>
import {DataTable} from 'laravel-vue-datatable';
import ActionDataTableButtons from "./ActionDataTableButtons";
import AssetMixin from "../mixins/assets";
import VueGallery from 'vue-gallery';

export default {
        name: 'items-table',

        mixins: [AssetMixin],

        props: [
            'itemsEndpoint',
            'unitTypeEndpoint',
            'packageTypeEndpoint',
            'itemGroupEndpoint',
            'storeItemEndpoint',
            'updateItemEndpoint',
            'destroyItemEndpoint',
            'itemImagesEndpoint',
            'addImageEndpoint',
            'updateImageEndpoint'
        ],

        components: {
            DataTable,
            ActionDataTableButtons,
            'gallery': VueGallery
        },

        data() {
            return {
                items: {},
                item: {
                    sku: '',
                    description: '',
                    group: '',
                    price_gross: null,
                    price_for: null,
                    unit_type_id: '',
                    minimum_order_unit: null,
                    package_id: ''
                },
                columns: [
                    {
                        name: "sku",
                        label: "SKU",
                        orderable: true
                    },
                    {
                        name: "description",
                        label: this.$gettext('Description'),
                        orderable: true
                    },
                    {
                        name: "group_formatted",
                        columnName: "group",
                        label: this.$gettext('Group'),
                        orderable: true
                    },
                    {
                        name: "price_gross",
                        label: this.$gettext('Gross price (€)'),
                        orderable: true
                    },
                    {
                        name: "unit.name_formatted",
                        columnName: "units.name",
                        label: this.$gettext('Unit type')
                    },
                    {
                        name: "package.name_formatted",
                        columnName: "packages.name",
                        label: this.$gettext('Package')
                    },
                    {
                        meta: {0: 'items'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search items'),
                },
                errors: [],
                unitTypes: [],
                unitType: '',
                packageTypes: [],
                packageType: '',
                groups: [],
                group: '',
                loader: true,
                rowData: {},
                editData: {},
                images: [],
                index: null,
                filterImageText: '',
                activeImageId: null,
                activeImageTitle: null,
                newImageSelected: false,
            }
        },

        mounted() {
            this.getItems();
            this.getUnitTypes();
            this.getPackageTypes();
            this.getGroups();
            this.getImages();
        },

        methods: {
            getItems(url = this.itemsEndpoint, options = this.tableProps) {
                axios.get(url, {
                    params: {
                        search: options.search,
                        length: options.length,
                        column: options.column,
                        dir: options.dir,
                        page: options.page,
                        unitType: this.unitType,
                        discountType: this.discountType,
                        packageType: this.packageType,
                        group: this.group,
                    }
                }).then(response => {
                    this.items = response.data;
                })
            },

            reloadTable(tableProps) {
                this.getItems(this.itemsEndpoint, tableProps);
            },

            getUnitTypes() {
                axios.get(this.unitTypeEndpoint).then(response => {
                    this.unitTypes = response.data;
                })
            },

            getPackageTypes() {
                axios.get(this.packageTypeEndpoint).then(response => {
                    this.packageTypes = response.data;
                })
            },

            getGroups() {
                axios.get(this.itemGroupEndpoint).then(response => {
                    this.groups = response.data;
                })
            },

            formatCurrency(price) {
                return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(price);
            },

            addItem() {
                this.errors = [];

                axios.post(this.storeItemEndpoint, {
                    'data': this.item,
                    'image': {
                        'sku': this.item.sku,
                        'title': this.activeImageTitle
                    }
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addItemModal").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            editItem() {
                this.errors = [];

                axios.post(this.updateItemEndpoint, {
                    'data': this.editData,
                    'image': {
                        'sku': this.editData.sku,
                        'title': this.activeImageTitle
                    }
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (this.activeImageTitle) {
                            axios.post(this.updateImageEndpoint, {
                                'title': this.activeImageTitle,
                                'sku': this.editData.sku
                            }).then(() => {})
                        }

                        if (response.status == 200) {
                            $("#itemModalEdit").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            uploadFile(e, action) {
                const file = e.target.files[0];
                const reader = new FileReader();
                const fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

                if (file && file.name) {
                    let extension = file.name.split('.').pop().toLowerCase(),  //file extension from input file
                        isSuccess = fileTypes.indexOf(extension) > -1;

                    if (isSuccess) {
                        reader.readAsDataURL(file);

                        reader.onload = e => {
                            const name = action === 'add' ? this.$refs.image_photo_path_add.files[0].name : this.$refs.image_photo_path.files[0].name;

                            axios.post(this.addImageEndpoint, {
                                'path': e.target.result,
                                'name': name
                            }).then(res => {
                                this.images = res.data.imagesUnique;

                                this.activeImageId = 0;
                                this.activeImageTitle = name;
                            })
                        };
                    }
                } else {
                    this.$refs.image_photo_path.value = null;
                }
            },

            deleteItem(id) {
                axios.post(this.destroyItemEndpoint, {id}).then(response => {
                    if (response.status == 200) {
                        $("#itemModalDelete").modal('hide');

                        window.location.reload();
                    }
                })
            },

            getRowData(actionName, data, meta) {
                this.errors = [];
                this.activeImageId = null;
                this.activeImageTitle = null;
                this.newImageSelected = false;

                this.rowData = Object.assign({}, data);
                this.editData = Object.assign({}, data);
                this.editData.item_photo_path = null;

                if (data.photo) this.activeImageId = this.images.findIndex(element => element.includes(this.rowData.photo.path));
            },

            getImages() {
                axios.get(this.itemImagesEndpoint).then(res => {
                    this.images = res.data.imagesUnique
                })
            },

            selectImage(index, title) {
                this.newImageSelected = true;
                this.activeImageId    = index
                this.activeImageTitle = title;
            },

            filteredImages() {
                let filteredImages = this.images.filter(image => {
                    const regex = new RegExp(this.filterImageText.trim().toLowerCase());
                    let matches = String(image).toLowerCase().match(regex);

                    if (matches) return matches;
                });

                if (this.rowData.photo && !this.newImageSelected) this.activeImageId = filteredImages.findIndex(element => element.includes(this.rowData.photo.path));

                return filteredImages;
            },

            chooseProductImage() {
                $("#productImageGallery").modal('hide');
                $("#productImageGalleryAdd").modal('hide');
            },
        }
    }
</script>

<style scoped>
.gallery-image {
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
    width: 19% !important;
    cursor: pointer;
    position: relative;
}

.gallery-image.active {
    border-radius: .25rem;
}

.overlay {
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.25);
    transition: background-color 0.5s;
    border: 2px solid #003a78;
    border-radius: .25rem;
    display: none;
}

.gallery-image.active .overlay {
    display: flex;
    flex-direction: column;
}
</style>
