<script>
export default {
    props: ['updateEndpoint'],

    data() {
        return {
            new_password: null,
            new_password_confirmation: null,
            errors: [],
        }
    },

    methods: {
        update() {
            this.errors = [];

            axios.post(this.updateEndpoint, {
                'new_password': this.new_password,
                'new_password_confirmation': this.new_password_confirmation,
            }).then(response => {
                if (response.data.errors) {
                    this.errors = response.data.errors;
                } else {
                    window.location.href = response.data;
                }
            })
        }
    }
}
</script>
