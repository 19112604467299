<script>
    import {DataTable} from 'laravel-vue-datatable';
    import ActionDataTableButtons from "./ActionDataTableButtons";
    import {eventBus} from "../app";

    export default {
        name: 'services-table',

        props: [
            'servicesEndpoint',
            'storeServiceEndpoint',
            'updateServiceEndpoint',
        ],

        components: { DataTable, ActionDataTableButtons },

        data() {
            return {
                columns: [
                    {
                        label: this.$gettext('Name'),
                        name: 'name',
                        orderable: true,
                    },
                    {
                        label: this.$gettext('Active'),
                        name: 'active_text',
                        columnName: 'active',
                        orderable: true,
                    },
                    {
                        label: this.$gettext('Required'),
                        name: 'required_text',
                        columnName: 'required',
                        orderable: true,
                    },
                    {
                        meta: {0: 'services'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search services'),
                },
                rowData: {},
                errors: [],
                editData: {
                    id: '',
                    parent_id: null,
                    name: '',
                    active: true,
                    required: false,
                    children: [
                        {
                            id: '',
                            parent_id: null,
                            name: '',
                            gross_price: '',
                            net_price: '',

                            active: true,
                            activeDisabled: true,

                            required: false,
                            requiredDisabled: true,

                            removeDisabled: true,
                        },
                    ],
                },
                newService: {
                    name: '',
                    active: true,
                    required: false,
                    children: [
                        {
                            name: '',
                            gross_price: '',
                            net_price: '',

                            active: true,
                            activeDisabled: true,

                            required: false,
                            requiredDisabled: true,

                            removeDisabled: true,
                        },
                    ],
                },
                deletedOptions: [],
            }
        },

        methods: {
            addService() {
                this.errors = [];

                axios.post(this.storeServiceEndpoint, this.newService).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addItemModal").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            editService() {
                this.errors = [];

                axios.post(this.updateServiceEndpoint, {
                    data: this.editData,
                    deleted_ids: this.deletedOptions
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#itemModalEdit").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            getRowData(actionName, data) {
                this.errors = [];

                this.rowData = Object.assign({}, data);
                this.editData = Object.assign({}, data);

                this.updateOptions()
            },

            formatCurrency(price) { 
                return new Intl.NumberFormat(window.Laravel.tenant, { style: "currency", currency: "EUR" }).format(price);
            },

            addOption(type) {
                this[type].children.push({
                    name: '',
                    gross_price: '',
                    net_price: '',

                    active: this[type].active,
                    activeDisabled: !this[type].active,

                    required: this[type].required,
                    requiredDisabled: !this[type].active || (this[type].active || !this[type].required) ? true : false,

                    removeDisabled: false,
                });

                if (this[type].children.length > 1) {
                    this[type].children.forEach(el => {
                        el.requiredDisabled = false;
                        el.removeDisabled = false;
                    })

                    if (this[type].active) this[type].children[0].activeDisabled = false;
                }
            },

            removeOption(type, index) {
                let self = this;

                this[type].children = this[type].children.filter(function(el, i) {
                    if (type === 'editData' && el.id && i === index) {
                        self.deletedOptions.push(self[type].children[i].id)
                    }

                    return i !== index;
                })

                if (this[type].children.length === 1) {
                    this[type].children[0].active = this[type].active;

                    this[type].children[0].activeDisabled = true;
                    this[type].children[0].removeDisabled = true;

                    if (this[type].required) {
                        this[type].children[0].required = true;
                        this[type].children[0].requiredDisabled = true;
                    }
                }
            },

            resetOptions() {
                this.newService = {
                    name: '',
                    active: true,
                    required: false,
                    children: [
                        {
                            name: '',
                            gross_price: '',
                            net_price: '',

                            active: true,
                            activeDisabled: true,

                            required: false,
                            requiredDisabled: true,

                            removeDisabled: true,
                        },
                    ],
                }
            },

            updateOptions() {
                if (this.editData.children.length === 1) {
                    this.editData.children[0].activeDisabled = this.editData.active;
                    this.editData.children[0].removeDisabled = true;

                    if (this.editData.required) {
                        this.editData.children[0].requiredDisabled = true;
                    }
                }
            },

            autoToggleOptions(type, option, value, index = null) {
                let countChildren = this[type].children.length;

                // Main active/required
                if (index === null) {
                    if (option === 'active') {
                        if (!value) {
                            this[type].required = false;
                            this[type].children.required = false;
                            this[type].children.requiredDisabled = true;
                        }

                        if (countChildren === 1) {
                            this[type].children[0].active = value;
                            this[type].children[0].activeDisabled = true;
                        } else {
                            if (value) {
                                this[type].children.forEach(el => {
                                    el.active = true;
                                    el.activeDisabled = false;
                                })
                            } else {
                                this[type].children.forEach(el => {
                                    el.active = false;
                                    el.required = false;
                                    el.activeDisabled = true;
                                    el.requiredDisabled = true;
                                    el.removeDisabled = false;
                                })
                            }
                        }
                    }

                    if (option === 'required') {
                        if (countChildren === 1) {
                            this[type].children[0].required = value;

                            this[type].children[0].requiredDisabled = true;
                        } else {
                            this[type].children.forEach(el => {
                                if (!value) el.required = false;

                                el.requiredDisabled = !value;
                            })
                        }
                    }
                } else {
                    if (option === 'active') {
                        let activeChildren = this[type].children.filter(el => el.active)

                        this[type].children.forEach(el => {
                            el.activeDisabled = false;
                        })

                        if (!value) {
                            this[type].children[index].required = false;
                            this[type].children[index].requiredDisabled = true;

                            if (activeChildren.length === 1) {
                                activeChildren[0].activeDisabled = true;
                            }
                        } else if (value && this[type].required) {
                            this[type].children[index].requiredDisabled = false;
                        }
                    }
                }
            }
        }
    }
</script>
