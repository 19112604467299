<script>
import {DataTable} from 'laravel-vue-datatable';
import ActionDataTableButtons from "./ActionDataTableButtons";
import { validationMixin } from 'vuelidate';
import { between } from 'vuelidate/lib/validators';

export default {
    name: 'discount',

    mixins: [
        validationMixin
    ],

    props: [
        'discountEndpoint',
        'wholesalersEndpoint',
        'createDiscountEndpoint',
        'updateDiscountEndpoint',
        'destroyDiscountEndpoint'
    ],

    components: { DataTable, ActionDataTableButtons },

    data() {
        return {
            columns: [
                {
                    name: "wholesaler.name",
                    columnName: 'wholesalers.name',
                    label: this.$gettext('Wholesaler'),
                    orderable: true
                },
                {
                    name: "discount",
                    label: this.$gettext('Discount %'),
                    orderable: true
                },
                {
                    meta: {0: 'discount'},
                    label: this.$gettext('Actions'),
                    component: ActionDataTableButtons,
                    event: 'click',
                    handler: this.getRowData
                },
            ],
            perPage: ['10', '25', '50', '100'],
            tableProps: {
                search: '',
                length: 10,
                column: 'id',
                dir: 'asc',
            },
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous'),
                placeholderSearch: this.$gettext('Search discounts'),
            },
            errors: [],
            rowData: {},
            editData: {},
            discount: {
                wholesaler: '',
                discount: ''
            },
            wholesalers: [],
            modalLoader: true
        }
    },

    mounted() {
        this.getWholesalers();
    },

    methods: {
        addDiscount() {
            this.errors = [];

            axios.post(this.createDiscountEndpoint, {
                wholesaler_id: this.discount.wholesaler.id,
                discount: this.discount.discount
            }).then(response => {
                if (response.data.errors) {
                    this.errors = response.data.errors;
                } else {
                    if (response.status === 200) {
                        $("#addDiscount").modal('hide');

                        window.location.reload();
                    }
                }
            })
        },

        editDiscount() {
            this.errors = [];

            axios.post(this.updateDiscountEndpoint, {
                id: this.editData.id,
                wholesaler_id: this.editData.wholesaler.id,
                discount: this.editData.discount
            }).then(response => {
                if (response.data.errors) {
                    this.errors = response.data.errors;
                } else {
                    if (response.status === 200) {
                        $("#itemModalEdit").modal('hide');

                        window.location.reload();
                    }
                }
            })
        },

        deleteDiscount(id) {
            axios.post(this.destroyDiscountEndpoint, {id}).then(response => {
                if (response.status === 200) {
                    $("#itemModalDelete").modal('hide');

                    window.location.reload();
                }
            })
        },

        getWholesalers() {
            this.modalLoader = true;

            axios.get(this.wholesalersEndpoint, {
                params: {
                    id: Object.keys(this.editData).length ? this.editData.wholesaler.id : null
                }
            }).then(response => {
                this.wholesalers = response.data;

                this.modalLoader = false;
            })
        },

        getRowData(actionName, data) {
            this.errors = [];

            this.rowData = Object.assign({}, data);
            this.editData = Object.assign({}, data);

            this.getWholesalers();
        }
    },

    validations: {
        discount: {
            discount: {
                between: between(0, 100)
            }
        },
        editData: {
            discount: {
                between: between(0, 100)
            }
        },
    }
}
</script>
