<template>
    <div>
        <div class="text-center text-md-right">
            <button class="btn color-henco-blue border-henco-blue" data-toggle="modal" data-target="#addInstaller" @click="getWholesalers()">
                <i class="fa fa-plus"></i>
                <span v-translate>Add installer</span>
            </button>
        </div>

        <div class="modal fade bd-example-modal-lg" id="addInstaller" tabindex="-1" role="dialog" aria-labelledby="addInstaller" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-henco-blue">
                        <h5 class="modal-title text-white" id="addInstallerTitle" v-translate>Add installer</h5>
                        <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form method="POST" @submit.prevent="addInstaller()">
                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="guid" v-translate>Guid: *</label>
                                <input type="text" class="form-control color-henco-blue" id="guid" name="guid" v-model="guid"
                                       :placeholder="$gettext('Enter guid...')" required/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.guid" v-for="(name, index) in errors.guid">
                                    <strong v-text="errors.guid[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="name" v-text="$gettext('Name') + ': *'"></label>
                                <input type="text" class="form-control color-henco-blue" id="name" name="name" v-model="name"
                                       :placeholder="$gettext('Enter name...')" required/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.name" v-for="(name, index) in errors.name">
                                    <strong v-text="errors.name[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="address" v-text="$gettext('Address') + ': *'"></label>
                                <input type="text" class="form-control color-henco-blue" id="address" name="address" v-model="address"
                                       :placeholder="$gettext('Enter address...')" required/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.address" v-for="(name, index) in errors.address">
                                    <strong v-text="errors.address[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="postcode" v-text="$gettext('Postcode') + ': *'"></label>
                                <input type="text" class="form-control color-henco-blue" id="postcode" name="postcode" v-model="postcode"
                                       :placeholder="$gettext('Enter postcode...')" required/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.postcode" v-for="(name, index) in errors.postcode">
                                    <strong v-text="errors.postcode[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left">
                                <label class="col-form-label color-henco-blue" for="city" v-text="$gettext('City') + ': *'"></label>
                                <input type="text" class="form-control color-henco-blue" id="city" name="city" v-model="city"
                                       :placeholder="$gettext('Enter city...')" required/>

                                <span class="alert-danger bg-transparent mb-1" role="alert"
                                      v-if="errors.city" v-for="(name, index) in errors.city">
                                    <strong v-text="errors.city[index]"></strong>
                                </span>
                            </div>

                            <div class="form-group text-left" v-if="!modalLoader" v-cloak>
                                <label class="col-form-label color-henco-blue" for="wholesaler"
                                       v-text="$gettext('Connected wholesalers:')"></label>
                                <v-autocomplete
                                    dense auto-select-first clearable filled solo
                                    multiple deletable-chips chips hide-selected v-model="wholesaler" ref="wholesaler"
                                    :items="wholesalers" color="white" item-text="name" item-value="id" return-object
                                    class="form-control color-henco-blue" :placeholder="$gettext('Please select wholesalers')"
                                    @change="hideMenu('wholesaler')" @input="showMenu('wholesaler')"
                                    @mouseup="showMenu('wholesaler')" @click="showMenu('wholesaler')"
                                >
                                </v-autocomplete>
                            </div>

                            <div class="position-relative" v-else>
                                <div class="loader-container">
                                    <div class="spinner-border color-henco-blue" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group text-right mb-0">
                                <button type="submit" class="btn bg-henco-blue text-white" v-text="$gettext('Add')"></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'add-installer',

        props: [
            'storeEndpoint',
            'wholesalersEndpoint'
        ],

        data() {
            return {
                guid: '',
                name: '',
                address: '',
                postcode: '',
                city: '',
                wholesalers: [],
                wholesaler: '',
                errors: [],
                modalLoader: true,
            }
        },

        methods: {
            addInstaller() {
                this.errors = [];

                axios.post(this.storeEndpoint, {
                    'guid': this.guid,
                    'name': this.name,
                    'address': this.address,
                    'postcode': this.postcode,
                    'city': this.city,
                    'wholesaler': this.wholesaler !== '' ? this.wholesaler : null,
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        $("#addInstaller").modal('hide');

                        window.location.reload();
                    }
                });
            },

            // Autocomplete inputs
            getWholesalers() {
                this.modalLoader = true;
                this.errors = [];

                this.guid = '';
                this.name = '';
                this.address = '';
                this.postcode = '';
                this.city = '';
                this.wholesaler = '';

                axios.get(this.wholesalersEndpoint).then(response => {
                    this.wholesalers = response.data;

                    this.modalLoader = false;
                })
            },
        },
    };
</script>
