<script>
import {DataTable} from 'laravel-vue-datatable';
import ActionDataTableButtons from "./ActionDataTableButtons";
import { validationMixin } from 'vuelidate';
import { between } from 'vuelidate/lib/validators';

export default {
    name: 'discount',

    mixins: [
        validationMixin
    ],

    props: [
        'discountEndpoint',
        'createDiscountEndpoint',
        'updateDiscountEndpoint',
        'destroyDiscountEndpoint'
    ],

    components: { DataTable, ActionDataTableButtons },

    data() {
        return {
            columns: [
                {
                    name: "item_group",
                    label: this.$gettext('Item group'),
                    orderable: true
                },
                {
                    name: "sub_item_group",
                    label: this.$gettext('Sub item group'),
                    orderable: true
                },
                {
                    meta: {0: 'discount'},
                    label: this.$gettext('Actions'),
                    component: ActionDataTableButtons,
                    event: 'click',
                    handler: this.getRowData
                },
            ],
            perPage: ['10', '25', '50', '100'],
            tableProps: {
                search: '',
                length: 10,
                column: 'id',
                dir: 'asc',
            },
            translate: {
                nextButton: this.$gettext('Next'),
                previousButton: this.$gettext('Previous'),
                placeholderSearch: this.$gettext('Search discounts'),
            },
            errors: [],
            rowData: {},
            editData: {},
            newDiscount: {
                item_number: '',
                item_group: '',
                sub_item_group: '',
                discounts: {
                    'A+': '',
                    'A': '',
                    'B+': '',
                    'B': '',
                    'C+': '',
                    'C': '',
                    'D': '',
                    'E': ''
                }
            }
        }
    },

    methods: {
        addDiscount() {
            this.errors = [];

            axios.post(this.createDiscountEndpoint, {
                item_number: this.newDiscount.item_number,
                item_group: this.newDiscount.item_group,
                sub_item_group: this.newDiscount.sub_item_group,
                discounts: this.newDiscount.discounts
            }).then(response => {
                if (response.data.errors) {
                    this.errors = response.data.errors;
                } else {
                    if (response.status == 200) {
                        $("#addDiscount").modal('hide');

                        window.location.reload();
                    }
                }
            })
        },

        editDiscount() {
            this.errors = [];

            axios.post(this.updateDiscountEndpoint, {
                id: this.editData.id,
                item_number: this.editData.item_number,
                item_group: this.editData.item_group,
                sub_item_group: this.editData.sub_item_group,
                discounts: this.editData.discounts
            }).then(response => {
                if (response.data.errors) {
                    this.errors = response.data.errors;
                } else {
                    if (response.status == 200) {
                        $("#itemModalEdit").modal('hide');

                        window.location.reload();
                    }
                }
            })
        },

        deleteDiscount(id) {
            axios.post(this.destroyDiscountEndpoint, {id}).then(() => {
                $("#itemModalDelete").modal('hide');

                window.location.reload();
            })
        },

        getRowData(actionName, data) {
            this.errors = [];

            this.rowData = Object.assign({}, data);
            this.editData = {
                'id': data.id,
                'item_number': data.item_number,
                'item_group': data.item_group,
                'sub_item_group': data.sub_item_group,
                'discounts': {
                    'A+': '',
                    'A': '',
                    'B+': '',
                    'B': '',
                    'C+': '',
                    'C': '',
                    'D': '',
                    'E': ''
                }
            }

            const discount_items = data.discount_items;

            for (let [key, value] in discount_items) {
                this.editData.discounts[discount_items[key].name] = discount_items[key].pivot.percentage
            }
        }
    },

    validations: {
        newDiscount: {
            discounts: {
                'A+': {
                    between: between(0, 100)
                },
                'A': {
                    between: between(0, 100)
                },
                'B+': {
                    between: between(0, 100)
                },
                'B': {
                    between: between(0, 100)
                },
                'C+': {
                    between: between(0, 100)
                },
                'C': {
                    between: between(0, 100)
                },
                'D': {
                    between: between(0, 100)
                },
                'E': {
                    between: between(0, 100)
                }
            }
        },
        editData: {
            discounts: {
                'A+': {
                    between: between(0, 100)
                },
                'A': {
                    between: between(0, 100)
                },
                'B+': {
                    between: between(0, 100)
                },
                'B': {
                    between: between(0, 100)
                },
                'C+': {
                    between: between(0, 100)
                },
                'C': {
                    between: between(0, 100)
                },
                'D': {
                    between: between(0, 100)
                },
                'E': {
                    between: between(0, 100)
                }
            }
        }
    }
}
</script>
