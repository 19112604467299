<script>
    import {DataTable} from 'laravel-vue-datatable';
    import ActionDataTableButtons from "./ActionDataTableButtons";
    import AssetMixin from "../mixins/assets";
    import RolesMixin from "../mixins/roles";
    import VueDocPreview from 'vue-doc-preview';
    import vSelect from 'vue-select';
    import {eventBus} from "../app";
    import { validationMixin } from 'vuelidate';
    import { minLength, maxLength } from 'vuelidate/lib/validators';

    export default {
        name: 'selection-tool-dashboard',

        props: [
            'projectsEndpoint',
            'projectEndpoint',
            'pdfEndpoint',
            'addDocumentsEndpoint',
            'deleteDocumentEndpoint',
            'deleteSelectionEndpoint',
            'columnsEndpoint',
            'executionPartnersEndpoint',
            'updateExecutionPartnerEndpoint',
            'quoteEndpoint',
            'registerEndpoint',
            'stagesEndpoint',
            'updateStageEndpoint',
            'updateMontageEndpoint',
            'refreshItemsEndpoint',
            'userRoleEndpoint',
            'apiEndpoint',
            'uploadMaterialListEndpoint',
            'updateConnectionsEndpoint',
            'toggleItemsEndpoint',
            'hideItemEndpoint',
            'createPrivateNoteEndpoint',
            'emailTemplatesEndpoint',
            'sendEmailsEndpoint',
            'updateProjectNumberEndpoint',
            'updatePhaseEndpoint',
            'getPdfUrlEndpoint',
            'downloadFileEndpoint',
            'updateAddressEndpoint',
            'updateContactEndpoint',
            'generateInvoiceEndpoint',
        ],

        mixins: [
            validationMixin,
            AssetMixin,
            RolesMixin,
        ],

        components: { DataTable, ActionDataTableButtons, VueDocPreview, vSelect },

        data() {
            return {
                project: [],
                selection: [],
                showImage: false,
                showItemId: '',
                selectedProject: {
                    id: null,
                    selectionId: null,
                    variation: null,
                    version: null,
                    phase: null,
                    stage: null,
                    status: null,
                    lost: false,
                    montagePrice: null,
                    coordinationSpending: null,
                    totalCustomCircuits: 0,
                    isRedrawn: 0,
                    effectively_drawn: 0,
                    notes: {},
                },
                grandTotal: 0,
                grandTotalItems: 0,
                itemsFloorAreaTotal: 0,
                itemsQuantityTotal: 0,
                selectionFileExtension: [],
                isMobile: false,
                itemsSum: {},
                itemsZone: 0,
                itemsZoneQty: 0,
                itemsQty: {},
                columns: [],
                variations: [],
                discounts: [],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'updated_at',
                    dir: 'desc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search projects'),
                },
                docs: [],
                docsCount: 0,
                errorDocs: [],
                executionPartners: [],
                executionPartner: '',
                fileToBeDeleted: null,
                selectedToBeDeleted: null,
                stages: [],
                filterStages: [],
                disabledStages: [],
                stageStatuses: [],
                filters: {
                    stage: '',
                    status: '',
                    search: '',
                    length: 10,
                },
                currentUserRole: '',
                showMaterialList: false,
                showUpdatePrices: false,
                materialList: null,
                materialListFloor: '',
                tenant: '',
                togglePriceType: 0,
                toggleGrossPrice: true,
                roleOfCreator: null,
                priceToCalculate: null,
                isToggleHidden: false,
                showPrices: false,
                canHidePrices: false,
                prices: [],
                private_note: null,
                editType: 'whole_project',
                templates: [],
                templatesContent: [],
                currentEmailTemplateIndex: 0,
                usedProjectNumber: false,
                allItemsHidden: false,
            }
        },

        created() {
            this.getColumns();
            this.getExecutionPartners();
            this.getUserRole();
            this.getStages();
        },

        mounted() {
            this.isMobile = screen.width <= 1023;

            this.tenant = window.Tenant;
        },

        computed: {
            currentVersion: {
                get() {
                    return this.selectedProject.variation + this.selectedProject.version;
                },
                set(newValue) {
                    this.selectedProject.variation = newValue.charAt(0);
                    this.selectedProject.version = newValue.substring(1)
                }
            }
        },

        methods: {
            getColumns() {
                axios.get(this.columnsEndpoint).then(response => {
                    this.columns = response.data;

                    this.$refs.historyTable.sortOrders = {};

                    for (let i = 0; i < this.columns.length; i++) {
                        const column = this.columns[i]['name'];

                        this.$refs.historyTable.sortOrders[column] = -1;
                    }

                    this.columns.push({
                        meta: {0: 'project'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    })
                })
            },

            formatCurrency(price) {
                return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(price);
            },

            showImageOverlay(itemId) {
                this.showItemId = itemId;
                this.showImage = true;
            },

            getStages() {
                axios.get(this.stagesEndpoint).then(response => {
                    this.stages = response.data;
                }).catch(error => {

                })
            },

            downloadPDF() {
                axios.post(this.pdfEndpoint, {
                    selection: this.selectedProject,
                    options: {
                        isToggleHidden: this.isToggleHidden,
                        showPrices: this.showPrices,
                        canHidePrices: this.canHidePrices,
                        prices: {
                            grandTotalItems: this.grandTotalItems,
                            grandTotal: this.grandTotal,
                            margin: this.discounts.margin,
                        }
                    }
                }).then(response => {
                    window.open(this.getPdfUrlEndpoint + '/' + response.data)
                }).catch(error => {

                });
            },

            getExecutionPartners() {
                axios.get(this.executionPartnersEndpoint).then(response => {
                    this.executionPartners = response.data;
                }).catch(error => {

                });
            },

            updateExecutionPartner() {
                axios.post(this.updateExecutionPartnerEndpoint, {
                    'id': this.selection.id,
                    'execution_partner_id': this.executionPartner ? this.executionPartner.id : null,
                }).then(() => {
                    this.selection.execution_partner_id = this.executionPartner ? this.executionPartner.id : null;

                    if (this.executionPartner && this.selection.including_installation) {
                        this.getEmailTemplates(this.selection.id)
                    }
                }).catch(error => {

                })
            },

            getRowData(actionName, data, meta, variation = null, version = null, toggle = false) {
                if (!variation) variation = data.selected_selection.variation
                if (!version) version = data.selected_selection.version

                const selectionId = toggle ? this.variations.filter(el =>
                    el.variation == variation && el.version == version)[0]['id']
                    : (!data.selected_selection ? data.selection_id : data.selected_selection.id);
                const project_id = data.id;

                this.materialList = null;
                this.materialListFloor = '';
                this.showMaterialList = false;
                this.showUpdatePrices = false;

                axios.get(this.projectEndpoint, {
                    params: {
                        id: project_id,
                        selection_id: selectionId,
                        variation: variation,
                        version: version,
                    }
                }).then(response => {
                    $('#administrative-tab').tab('show')

                    this.variations = response.data.variations;
                    this.discounts = {
                        'discount': response.data.discounts.discount,
                        'margin': response.data.discounts.margin > 0 ? Math.round(response.data.discounts.margin * 100) : 0,
                    };
                    this.selection = response.data.selection;
                    this.project = this.selection.project;
                    this.roleOfCreator = response.data.role_of_creator;
                    this.docs = [];
                    this.docsCount = 0;
                    this.errorDocs = [];
                    this.selectedProject = {
                        id: this.project.id,
                        selectionId: this.selection.id,
                        variation: this.selection.variation,
                        version: this.selection.version,
                        phase: this.selection.phase,
                        stage: this.selection.stage_id,
                        status: this.selection.stage_status_id,
                        lost: false,
                        montagePrice: this.selection.prices.montage_price,
                        coordinationSpending: this.selection.prices.coordination_spending,
                        totalCustomCircuits: this.selection.total_custom_circuits,
                        isRedrawn: this.selection.is_redrawn,
                        effectivelyDrawn: this.selection.effectively_drawn,
                        notes: this.selection.notes,
                        address: this.selection.project_info.address ?? '',
                        installerPhone: this.selection.project_info.installer_phone,
                        wholesalerPhone: this.selection.project_info.wholesaler_phone,
                    }
                    this.prices = this.selection.prices;
                    this.executionPartner = this.selection.execution_partner_id;
                    this.stageStatuses = this.stages.filter(el => el.id === this.selectedProject.stage)[0].statuses;
                    this.toggleGrossPrice = true;
                    this.allItemsHidden = response.data.allItemsHidden;

                    switch (this.roleOfCreator) {
                        case 'contractor-installer':
                            switch (this.currentUserRole) {
                                case 'contractor-installer':
                                    this.priceToCalculate = 'gross_price';
                                    this.isToggleHidden = true;
                                    this.toggleGrossPrice = false;
                                    break;
                                default:
                                    this.priceToCalculate = 'net_price';
                            }
                            break;
                        case 'wholesaler':
                            switch (this.currentUserRole) {
                                case 'wholesaler':
                                    this.priceToCalculate = 'gross_price';
                                    this.isToggleHidden = true;
                                    this.toggleGrossPrice = false;
                                    break;
                                default:
                                    this.priceToCalculate = 'net_price';
                            }
                            break;
                        default:
                            this.priceToCalculate = 'net_price';
                    }

                    if (this.tenant !== 'be' || (this.tenant === 'be' && ['henco-floor-super-user', 'henco-floor-internal-user'].includes(this.currentUserRole))) this.showPrices = true;

                    if (['henco-floor-super-user', 'henco-floor-internal-user'].includes(this.roleOfCreator)
                        && ['henco-floor-super-user', 'henco-floor-internal-user'].includes(this.currentUserRole)) this.canHidePrices = true;

                    this.selectedProject.lost = this.stageStatuses.filter(el => el.id === this.selectedProject.status)[0].name === 'Lost';

                    if (actionName === 'view') {
                        if (this.selection.docs) {
                            const docs = this.selection.docs;

                            for (let i = 0; i < docs.length; i++) {
                                this.selectionFileExtension[i] = docs[i].name.substring(docs[i].name.lastIndexOf('.') + 1);
                            }
                        }

                        this.togglePrice()

                        if (this.currentUserRole === 'henco-floor-super-user' || this.currentUserRole === 'henco-floor-internal-user') this.getEmailTemplates(this.selection.id);
                    } else if (actionName === 'redirect' || actionName === 'clone') {
                        sessionStorage.removeItem('selection-data');

                        this.selection.editType = this.editType;

                        sessionStorage.setItem('selection-data', JSON.stringify(this.selection));

                        if (this.selection.project_type === 'quote' ||
                            (this.selection.project_type === 'register' && this.editType === 'copy_to_quote')) {
                            window.location.href = this.quoteEndpoint;
                        } else {
                            window.location.href = this.registerEndpoint;
                        }
                    }

                    switch (this.currentUserRole) {
                        case 'execution-partner':
                        case 'sales-engineer':
                            this.filterStages = this.stages.filter(el => el.name === 'Quotation');
                            this.disabledStages = this.stages.filter(el => el.name !== 'Quotation');
                            break;
                        case 'contractor-installer':
                        case 'wholesaler':
                            this.filterStages = this.stages.filter(el => ['Quotation', 'Drawing'].includes(el.name));
                            this.disabledStages = this.stages.filter(el => !['Quotation', 'Drawing'].includes(el.name));
                            break;
                        default:
                            this.filterStages = this.stages;
                    }

                })
            },

            getStageStatuses(stage, isModal = false) {
                if (isModal) {
                    this.stageStatuses = this.stages.filter(el => el.id === stage)[0].statuses;
                    this.selectedProject.status = this.stageStatuses[0].id
                } else {
                    if (stage !== '') this.stageStatuses = this.stages.filter(el => el.id === stage)[0].statuses;
                    this.filters.status = '';
                }
            },

            updateStage() {
                axios.post(this.updateStageEndpoint, {
                    id: this.selectedProject.selectionId,
                    stage_id: this.selectedProject.stage,
                    stage_status_id: this.selectedProject.status,
                }).then(() => {
                    alert(this.$gettext('Success'));

                    this.selectedProject.lost = this.stageStatuses.filter(el => el.id === this.selectedProject.status)[0].name === 'Lost';
                }).catch(error => {

                })
            },

            groupItems(items) {
                return items.reduce(function (r, a) {
                    if (a.item) {
                        r[a.item.group] = r[a.item.group] || [];
                        r[a.item.group].push(a);
                    }

                    return r;
                }, Object.create(null));
            },

            groupSum(items) {
                return {
                    'price': Object.values(items).reduce((sum, item) => sum + item[this.priceToCalculate], 0),
                    'total_qty': Object.values(items).reduce((sum, item) => sum + item['quantity'], 0)
                };
            },

            // Attach additional docs
            uploadFile(e) {
                const docs = e.target.files;
                this.errorDocs = [];
                this.docsCount = 0;

                for (let i = 0; i < docs.length; i++) this.upload(i, docs[i]);
            },

            upload(i, file) {
                const reader = new FileReader();
                const fileTypes = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'docx', 'dwg'];
                this.$forceUpdate();

                if (file && file.name) {
                    const extension = file.name.split('.').pop().toLowerCase(),  //file extension from input file
                        isSuccess = fileTypes.indexOf(extension) > -1;

                    if (isSuccess) {
                        reader.readAsDataURL(file);

                        reader.onload = e => {
                            this.docs[i] = {
                                'file': e.target.result,
                                'originalName': file.name
                            }
                        };

                        this.docsCount += 1;
                    } else {
                        this.errorDocs.push(file.name);
                    }
                } else {
                    this.errorDocs.push(file.name);
                }
            },

            addDocuments() {
                if (this.docs.length) {
                    axios.post(this.addDocumentsEndpoint, {
                        'id': this.selection.id,
                        'docs': this.docs
                    }).then(response => {
                        alert(this.$gettext('Success'));

                        this.$refs.docs.value = '';
                        this.docs = [];

                        this.getRowData('view', response.data, {0: 'project'}, this.selection.variation, this.selection.version);
                    })
                } else {
                    alert(this.$gettext('Please choose documents!'));
                }
            },

            deleteDoc(document) {
                axios.post(this.deleteDocumentEndpoint, {
                    'id': this.selection.id,
                    'document': document
                }).then(response => {
                    alert(this.$gettext('Success'));

                    this.fileToBeDeleted = null;

                    this.getRowData('view', response.data, {0: 'project'}, this.selection.variation, this.selection.version);

                    $('#fileConfirmDelete').modal('hide');

                    window.setTimeout(() => {
                        $('body').addClass('modal-open');
                    }, 500)
                }).catch(error => {

                })
            },

            deleteSelection() {
                axios.post(this.deleteSelectionEndpoint, {
                    'id': this.selection.id
                }).then(() => {
                    alert(this.$gettext('Success'));

                    window.location.reload();
                }).catch(error => {

                })
            },

            passData(type, data) {
                type === 'selection' ? this.selectedToBeDeleted = data : this.fileToBeDeleted = data.name;
            },

            hideModal() {
                this.fileToBeDeleted = null;

                $('#fileConfirmDelete').modal('hide');

                window.setTimeout(() => {
                    $('body').addClass('modal-open');
                }, 500)
            },

            // Capitalize string
            capitalize(sentence) {
                return sentence.charAt(0).toUpperCase() + sentence.slice(1);
            },

            updateMontagePrices() {
                axios.post(this.updateMontageEndpoint, {
                    'id': this.selection.id,
                    'montage_price': this.selectedProject.montagePrice,
                    'coordination_spending': this.selectedProject.coordinationSpending,
                    'margin': parseFloat(this.discounts.margin)
                }).then(response => {
                    this.getRowData('view', response.data, {0: 'project'}, this.selectedProject.variation, this.selectedProject.version);
                }).catch(error => {

                })
            },

            refreshItems(selection) {
                axios.post(this.refreshItemsEndpoint, {selection}).then(response => {
                    this.getRowData('view', response.data, {0: 'project'}, response.data.variation, response.data.version);
                }).catch(error => {

                })
            },

            getUserRole() {
                return axios.get(this.userRoleEndpoint).then(response => {
                    this.currentUserRole = response.data.role;
                }).catch(error => {

                })
            },

            updateConnections() {
                axios.post(this.updateConnectionsEndpoint, {
                    'id': this.selection.id,
                    'total_custom_circuits': this.selectedProject.totalCustomCircuits,
                    'is_redrawn': this.selectedProject.isRedrawn,
                    'effectively_drawn': this.selectedProject.effectivelyDrawn,
                }).then(response => {
                    this.getRowData('view', response.data, {0: 'project'}, response.data.variation, response.data.version);
                }).catch(error => {

                });
            },

            createPrivateNote() {
                axios.post(this.createPrivateNoteEndpoint, {
                    'id': this.selection.id,
                    'private_note': this.private_note
                }).then(response => {
                    this.getRowData('view', response.data, {0: 'project'}, response.data.variation, response.data.version);

                    this.private_note = null;
                }).catch(error => {

                })
            },

            getMaterialList(e) {
                this.materialList = e.target.files[0];
            },

            uploadMaterialList(selection) {
                let formData = new FormData();

                formData.append('materialList', this.materialList);
                formData.append('materialListFloor', this.materialListFloor);
                formData.append('selection', JSON.stringify(selection));

                axios.post(this.uploadMaterialListEndpoint,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(res => {
                    this.getRowData('view', res.data, {0: 'project'}, res.data.variation, res.data.version);
                }).catch(error => {

                });
            },

            togglePrice(isToggled = false) {
                isToggled = this.toggleGrossPrice = !this.toggleGrossPrice;
                this.grandTotal = 0;
                this.grandTotalItems = 0;
                this.itemsFloorAreaTotal = 0;
                this.itemsQuantityTotal = 0;
                this.itemsSum = {};
                this.priceToCalculate = 'net_price';
                const self = this;

                if (this.toggleGrossPrice || !this.selection.including_installation) {
                    this.priceToCalculate = 'gross_price';
                }

                for (const [key, items] of Object.entries(this.selection.floors)) {
                    this.itemsSum[key] = Object.values(items['items']).reduce(function (sum, item) {
                        return sum + item[self.priceToCalculate];
                    }, 0)

                    this.itemsQty[key] = Object.values(items['items']).reduce(function (sum, item) {
                        return sum + item.quantity;
                    }, 0)

                    this.grandTotal += this.itemsSum[key];
                    this.itemsQuantityTotal += this.itemsQty[key];
                    this.itemsFloorAreaTotal += items['floor_area'];

                    this.groupSum(items['items']);
                }

                if (this.selection.zone_control_items) {
                    this.itemsZone = Object.values(this.selection.zone_control_items).reduce(function (sum, item) {
                        return sum + (item.quantity * item[self.priceToCalculate]);
                    }, 0)

                    this.itemsZoneQty = Object.values(this.selection.zone_control_items).reduce(function (sum, item) {
                        return sum + item.quantity;
                    }, 0)

                    this.grandTotal += this.itemsZone;
                    this.itemsQuantityTotal += this.itemsZoneQty;

                    this.groupSum(this.itemsZone);
                }

                switch (this.roleOfCreator) {
                    case 'contractor-installer':
                        switch (this.currentUserRole) {
                            case 'contractor-installer':
                                this.grandTotalItems = this.prices.net_materials;
                                this.grandTotal = this.selection.including_installation ? this.prices.net_price_installer : this.prices.gross_price_installer;
                                break;
                            default:
                                this.toggleFinalPrice();
                                break;
                        }
                        break;
                    case 'wholesaler':
                        switch (this.currentUserRole) {
                            case 'wholesaler':
                                this.grandTotalItems = this.prices.net_materials;
                                this.grandTotal = this.selection.including_installation ? this.prices.net_price_wholesaler : this.prices.gross_price_wholesaler;
                                break;
                            default:
                                this.toggleFinalPrice();
                                break;
                        }
                        break;
                    default:
                        this.toggleFinalPrice();
                        break;
                }
            },

            toggleFinalPrice() {
                if (!this.selection.including_installation) {
                    this.grandTotalItems = this.prices.gross_materials;
                    this.grandTotal = this.prices.gross_materials;
                } else if (this.toggleGrossPrice && this.selection.including_installation) {
                    this.grandTotalItems = this.prices.gross_materials;
                    this.grandTotal = this.prices.gross_final_price;
                } else if (!this.toggleGrossPrice && this.selection.including_installation) {
                    this.grandTotalItems = this.prices.net_materials;
                    this.grandTotal = this.prices.net_final_price;
                }
            },

            hideItem(id) {
                axios.post(this.hideItemEndpoint, {id}).then(res => {
                    this.getRowData('view', res.data, {0: 'project'}, res.data.variation, res.data.version);
                }).catch(error => {

                })
            },

            toggleItems() {
                axios.post(this.toggleItemsEndpoint, {
                    id: this.selection.id,
                    toggle: this.allItemsHidden
                }).then(res => {
                    this.allItemsHidden = !this.allItemsHidden;

                    this.getRowData('view', res.data, {0: 'project'}, res.data.variation, res.data.version);
                }).catch(error => {

                })
            },

            getEmailTemplates(id) {
                if (this.selection.execution_partner_id && this.selection.including_installation) {
                    axios.get(this.emailTemplatesEndpoint + '?id=' + id).then(res => {
                        this.templates = res.data;
                        this.templatesContent = JSON.parse(JSON.stringify(this.templates));

                        window.setTimeout(() => {
                            eventBus.$emit('updateText', this.templatesContent[0].body);
                        }, 1500)

                        eventBus.$on('getText', (data) => {
                            this.templatesContent[0].body = data;
                        });
                    }).catch(error => {

                    })
                }
            },

            sendEmail(id, template) {
                axios.post(this.sendEmailsEndpoint, {
                    id: id,
                    email: template
                }).then(() => {
                    alert(this.$gettext('Success'))
                })
            },

            updateProjectNumber(id) {
                this.usedProjectNumber = false;

                axios.patch(this.updateProjectNumberEndpoint, {
                    'id': id,
                    'project_number': this.selection.project_info.project_number
                }).then(res => {
                    alert(this.$gettext('Success'))

                    this.getRowData('view', res.data, {0: 'project'}, this.selection.variation, this.selection.version);
                }).catch(error => {
                    this.usedProjectNumber = error.response.data.message;
                })
            },

            updatePhase(id) {
                axios.patch(this.updatePhaseEndpoint, {
                    'id': id,
                    'phase': this.selectedProject.phase
                }).then(res => {
                    alert(this.$gettext('Success'))

                    this.getRowData('view', res.data, {0: 'project'}, this.selection.variation, this.selection.version);
                }).catch(error => {

                })
            },

            updateBody(index) {
                this.currentEmailTemplateIndex = index;
                this.templatesContent[index].body = this.templates[index].body;

                window.setTimeout(() => {
                    eventBus.$emit('updateText', this.templatesContent[index].body)
                }, 200);

                eventBus.$on('getText', (data) => {
                    this.templatesContent[index].body = data;
                });
            },

            showFirstTab() {
                this.currentEmailTemplateIndex = 0;

                $('#template_0-tab').tab('show')
            },

            saveFile(file) {
                axios.get(this.downloadFileEndpoint, {
                    responseType: 'blob',
                    params: {
                        project: this.project.id,
                        fileName: file.name
                    }
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');

                    link.href = url;
                    link.setAttribute('download', file.name);

                    document.body.appendChild(link);

                    link.click();
                })
            },

            updateProjectAddress(id) {
                axios.patch(this.updateAddressEndpoint, {
                    'id': id,
                    'address': this.selectedProject.address
                }).then(res => {
                    alert(this.$gettext('Success'))

                    this.getRowData('view', res.data, {0: 'project'}, this.selection.variation, this.selection.version);
                }).catch(error => {

                })
            },

            updateContacts(id, type) {
                axios.patch(this.updateContactEndpoint, {
                    'id': id,
                    'phone': type === 'installer' ? this.selectedProject.installerPhone : this.selectedProject.wholesalerPhone,
                    'type': type
                }).then(res => {
                    alert(this.$gettext('Success'))

                    this.getRowData('view', res.data, {0: 'project'}, this.selection.variation, this.selection.version);
                }).catch(error => {

                })
            },

            generateInvoice(id) {
                axios.post(this.generateInvoiceEndpoint, {id}, {
                    responseType: 'blob',
                }).then(res => {
                    const fileName = new Date().getFullYear() + this.selection.project_info.project_number + ' ' + this.selection.variation + '.xlsx';
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');

                    link.href = url;
                    link.setAttribute('download', fileName);

                    document.body.appendChild(link);

                    link.click();
                })
            },
        },

        validations: {
            selectedProject: {
                installerPhone: {
                    minLength: minLength(8),
                    maxLength: maxLength(15),
                },
                wholesalerPhone: {
                    minLength: minLength(8),
                    maxLength: maxLength(15),
                },
            },
        },
    }
</script>

<style>
.tooltip {
    opacity: 1;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 260px;
    z-index: 1;
    left: 20px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    font-size: 12px;
}
</style>
