<script>
    export default {
        name: 'switch-tenant',

        props: [
            'tenantsEndpoint',
            'enterTenantEndpoint'
        ],

        data() {
            return {
                tenants: [],
                tenant: [],
            }
        },

        mounted() {
            this.getTenants();
        },

        methods: {
            getTenants() {
                axios.get(this.tenantsEndpoint).then(res => {
                    this.tenants = res.data;
                })
            },

            enterTenant() {
                axios.post(this.enterTenantEndpoint, {
                    'tenant': this.tenant
                }).then((res) => {
                    window.location = res.data
                });
            }
        }
    }
</script>

<style>
#tenant-select .v-menu {
    position: absolute;
}
</style>
