<script>
import {DataTable} from 'laravel-vue-datatable';
import ActionDataTableButtons from "./ActionDataTableButtons";
import {eventBus} from "../app";

export default {
        name: 'items-table',

        props: [
            'templatesEndpoint',
            'storeTemplateEndpoint',
            'updateTemplateEndpoint',
            'destroyTemplateEndpoint',
            'variablesEndpoint',
        ],

        components: {
            DataTable,
            ActionDataTableButtons,
        },

        data() {
            return {
                templates: {},
                columns: [
                    {
                        name: "id",
                        label: "ID",
                        orderable: true
                    },
                    {
                        name: "name",
                        label: this.$gettext('Template name'),
                        orderable: true
                    },
                    {
                        meta: {0: 'templates'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search templates'),
                },
                newTemplate: {
                    name: '',
                    subject: '',
                    body: '',
                    to: '',
                    cc: '',
                },
                errors: [],
                rowData: {},
                editData: {},
                variables: [],
            }
        },

        mounted() {
            this.getItems();
            this.getVariables();
        },

        methods: {
            getItems(url = this.templatesEndpoint, options = this.tableProps) {
                axios.get(url, {
                    params: {
                        search: options.search,
                        length: options.length,
                        column: options.column,
                        dir: options.dir,
                        page: options.page,
                    }
                }).then(response => {
                    this.templates = response.data;
                })
            },

            reloadTable(tableProps) {
                this.getItems(this.templatesEndpoint, tableProps);
            },

            addTemplate() {
                this.errors = [];

                axios.post(this.storeTemplateEndpoint, {
                    'data': this.newTemplate
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        $("#addItemModal").modal('hide');

                        window.location.reload();
                    }
                })
            },

            editTemplate() {
                this.errors = [];

                axios.post(this.updateTemplateEndpoint, {
                    'data': this.editData
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#itemModalEdit").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            deleteTemplate(id) {
                axios.post(this.destroyTemplateEndpoint, {
                    id
                }).then(response => {
                    if (response.status == 200) {
                        $("#itemModalDelete").modal('hide');

                        window.location.reload();
                    }
                })
            },

            resetNewTemplate() {
                this.newTemplate.body = '';

                eventBus.$on('getText', (data) => {
                    this.newTemplate.body = data;
                });

                eventBus.$emit('updateText', this.newTemplate.body);
            },

            getRowData(actionName, data) {
                this.errors = [];
                this.newTemplate.body = '';

                this.rowData = Object.assign({}, data);
                this.editData = Object.assign({}, data);

                this.rowData.cc = JSON.parse(this.rowData.cc);
                this.editData.cc = JSON.parse(this.editData.cc);

                eventBus.$on('getText', (data) => {
                    this.editData.body = data;
                });

                eventBus.$emit('updateText', this.editData.body);
            },

            getVariables() {
                axios.get(this.variablesEndpoint).then(res => {
                    this.variables = res.data;
                })
            },

            passVariable(variable, form) {
                if (form === 'new') {
                    this.newTemplate.body += '{{' + variable + '}}'
                    eventBus.$emit('updateText', this.newTemplate.body);
                } else {
                    this.editData.body += '{{' + variable + '}}';
                    eventBus.$emit('updateText', this.editData.body);
                }
            }
        }
    }
</script>

<style scoped>
.gallery-image {
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
    width: 19% !important;
    cursor: pointer;
    position: relative;
}

.gallery-image.active {
    border-radius: .25rem;
}

.overlay {
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.25);
    transition: background-color 0.5s;
    border: 2px solid #003a78;
    border-radius: .25rem;
    display: none;
}

.gallery-image.active .overlay {
    display: flex;
    flex-direction: column;
}
</style>
