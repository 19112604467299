<script>
    import {DataTable} from 'laravel-vue-datatable';
    import ActionDataTableButtons from "./ActionDataTableButtons";
    import {eventBus} from "../app";

    export default {
        name: 'margins-table',

        props: [
            'marginsEndpoint',
            'storeMarginEndpoint',
            'updateMarginEndpoint',
            'destroyMarginEndpoint',
            'installersEndpoint',
            'wholesalersEndpoint',
        ],

        components: { DataTable, ActionDataTableButtons },

        data() {
            return {
                columns: [
                    {
                        label: this.$gettext('Installer'),
                        name: 'installer.name',
                        columnName: 'installers.name',
                        orderable: true,
                    },
                    {
                        label: this.$gettext('Wholesaler'),
                        name: 'wholesaler.name',
                        columnName: 'wholesalers.name',
                        orderable: true,
                    },
                    {
                        label: this.$gettext('Margin'),
                        name: 'margin',
                        orderable: true,
                    },
                    {
                        meta: {0: 'margins'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search margins'),
                },
                rowData: {},
                errors: [],
                editData: {
                    installer: {},
                    wholesaler: {},
                    margin: ''
                },
                modalLoader: false,
                usersCalled: false,
                installers: [],
                wholesalers: [],
                margin: {
                    installer: {},
                    wholesaler: {},
                    margin: ''
                }
            }
        },

        methods: {
            addMargin() {
                this.errors = [];

                axios.post(this.storeMarginEndpoint, {
                    'installer': this.margin.installer ? this.margin.installer.id : '',
                    'wholesaler': this.margin.wholesaler ? this.margin.wholesaler.id : '',
                    'margin': parseFloat(this.margin.margin),
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addItemModal").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            editMargin() {
                this.errors = [];

                axios.post(this.updateMarginEndpoint, {
                    'installer': this.editData.installer ? this.editData.installer.id : '',
                    'wholesaler': this.editData.wholesaler ? this.editData.wholesaler.id : '',
                    'margin': parseFloat(this.editData.margin),
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#itemModalEdit").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            async getMargin() {
                this.margin = {
                    installer: {},
                    wholesaler: {},
                    margin: ''
                }

                if (!this.usersCalled) {
                    await this.getWholesalers();
                    await this.getInstallers();
                }

                this.usersCalled = true;

                this.modalLoader = false;
            },

            // Autocomplete inputs
            async getWholesalers(id = null) {
                this.modalLoader = true;

                return axios.get(this.wholesalersEndpoint, {
                    params: {
                        installerId: id,
                    }
                }).then(response => {
                    this.wholesalers = response.data;
                    this.modalLoader = false;
                })
            },

            // Autocomplete inputs
            async getInstallers(id = null) {
                this.modalLoader = true;

                return axios.get(this.installersEndpoint, {
                    params: {
                        wholesalerId: id,
                    }
                }).then(response => {
                    this.installers = response.data;
                    this.modalLoader = false;
                })
            },

            // When selected autocomplete for wholesaler/installer
            saveChoice(type, clear = null) {
                if (clear === null) {
                    if (type === 'installers') {
                        if ( this.margin.installer) {
                            this.getWholesalers(this.margin.installer.id)
                        } else {
                            this.getWholesalers()
                        }
                    }

                    if (type === 'wholesalers') {
                        if ( this.margin.wholesaler) {
                            this.getInstallers(this.margin.wholesaler.id)
                        } else {
                            this.getInstallers()
                        }
                    }
                } else {
                    if (type === 'installers') this.getWholesalers()

                    if (type === 'wholesalers') this.getInstallers()
                }
            },

            deleteMargin(id) {
                axios.post(this.destroyMarginEndpoint, {id}).then(response => {
                    if (response.status == 200) {
                        $("#itemModalDelete").modal('hide');

                        window.location.reload();
                    }
                })
            },

            getRowData(actionName, data) {
                this.errors = [];

                this.rowData = Object.assign({}, data);
                this.editData = Object.assign({}, data);
            }
        }
    }
</script>
