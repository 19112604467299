<script>
    import {DataTable} from 'laravel-vue-datatable';
    import ActionDataTableButtons from "./ActionDataTableButtons";

    export default {
        name: 'wholesalers-table',

        props: [
            'wholesalersEndpoint',
            'installersEndpoint',
            'updateWholesalerEndpoint',
            'destroyWholesalerEndpoint'
        ],

        components: { DataTable, ActionDataTableButtons },

        data() {
            return {
                wholesalers: {},
                columns: [
                    {
                        name: "name",
                        label: this.$gettext('Name'),
                        orderable: true
                    },
                    {
                        name: "address",
                        label: this.$gettext('Address'),
                        orderable: true
                    },
                    {
                        name: "postcode",
                        label: this.$gettext('Postcode'),
                        orderable: true
                    },
                    {
                        name: "city",
                        label: this.$gettext('City'),
                        orderable: true
                    },
                    {
                        meta: {0: 'wholesalers'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search wholesalers'),
                },
                rowData: {},
                installers: [],
                editData: {
                    id: '',
                    guid: '',
                    name: '',
                    address: '',
                    postcode: '',
                    city: '',
                    installers: [],
                },
                errors: [],
                modalLoader: true
            }
        },

        mounted() {
            this.getWholesalers();
        },

        methods: {
            getWholesalers(url = this.wholesalersEndpoint, options = this.tableProps) {
                axios.get(url, {
                    params: {
                        search: options.search,
                        length: options.length,
                        column: options.column,
                        dir: options.dir,
                        page: options.page
                    }
                }).then(response => {
                    this.wholesalers = response.data;
                })
            },

            reloadTable(tableProps) {
                this.getWholesalers(this.wholesalersEndpoint, tableProps);
            },

            getInstallers() {
                axios.get(this.installersEndpoint).then(response => {
                    this.installers = response.data;
                    this.modalLoader = false;
                })
            },

            editRow() {
                this.errors = [];

                axios.post(this.updateWholesalerEndpoint, {
                    'data': this.editData
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#itemModalEdit").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            deleteRow(id) {
                axios.post(this.destroyWholesalerEndpoint, {id}).then(response => {
                    if (response.status == 200) {
                        $("#itemModalDelete").modal('hide');

                        window.location.reload();
                    }
                })
            },

            getRowData(actionName, data) {
                this.errors = [];

                this.getInstallers();

                this.rowData = Object.assign({}, data);
                this.editData = Object.assign({}, data);
            }
        }
    }
</script>
