/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import {req} from "vuelidate/lib/validators/common";

require('./bootstrap');

export const eventBus = new Vue();

window.Vue = require('vue').default;
const Vuetify = require('vuetify');
const moment = require('moment');

import Vue from 'vue'
import Vuex from 'vuex'
import GetTextPlugin from 'vue-gettext';
import translations from './translations/translations.json';
import TailablePagination from 'tailable-pagination';
const Vue2Editor = require('vue2-editor');

Vue.use(Vuex);
Vue.use(Vuetify, {
    iconfont: ['md', 'mdi', 'faSvg', 'mdiSvg'],
});
Vue.use(TailablePagination);
Vue.use(Vue2Editor);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('selection-tool', require('./components/SelectionTool.vue').default);
Vue.component('selection-tool-dashboard', require('./components/SelectionToolDashboard.vue').default);

Vue.component('tenant', require('./components/Tenant.vue').default);

Vue.component('items-table', require('./components/ItemsTable.vue').default);

Vue.component('discount-be', require('./components/DiscountsBe.vue').default);
Vue.component('discount-nl', require('./components/DiscountsNl.vue').default);

Vue.component('systems-table', require('./components/SystemsTable.vue').default);

Vue.component('tech-table', require('./components/TechTable.vue').default);

Vue.component('connections-table', require('./components/ConnectionsTable.vue').default);

Vue.component('wholesalers-table', require('./components/WholesalersTable.vue').default);
Vue.component('add-wholesaler', require('./components/AddWholesaler.vue').default);

Vue.component('installers-table', require('./components/InstallersTable.vue').default);
Vue.component('add-installer', require('./components/AddInstaller.vue').default);

Vue.component('execution-partners-table', require('./components/ExecutionPartnersTable.vue').default);
Vue.component('add-execution-partner', require('./components/AddExecutionPartner.vue').default);

Vue.component('margins-table', require('./components/MarginsTable').default);

Vue.component('services-table', require('./components/ServicesTable.vue').default);

Vue.component('roles-table', require('./components/RolesTable').default);

Vue.component('coefficients-table', require('./components/CoefficientsTable').default);

Vue.component('settings-form', require('./components/SettingsForm.vue').default);

Vue.component('editor', require('./components/VueEditor.vue').default);
Vue.component('email-templates-table', require('./components/EmailTemplatesTable.vue').default);

Vue.component('switch-tenant', require('./components/SwitchTenant.vue').default);

Vue.component('update-password', require('./components/UpdatePassword.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

function init(language) {
    Vue.use(GetTextPlugin, {
        availableLanguages: {
            en_US: 'English',
            nl_NL: 'Netherlands',
            fr_FR: 'France',
        },
        defaultLanguage: language,
        languageVmMixin: {
            computed: {
                currentKebabCase: function () {
                    return this.current.toLowerCase().replace('_', '-')
                },
            },
        },
        translations: translations,
        silent: true,
    });

    const app = new Vue({
        el: '#app',
        vuetify: new Vuetify(),
        store,
    });
}

Vue.mixin({
    methods: {
        // Hide menu for autocomplete
        hideMenu(ref) {
            this.$refs[ref].$children[1].$el.classList.add('d-none');
        },

        // Show menu for autocomplete
        showMenu(ref) {
            this.$refs[ref].$children[1].$el.classList.remove('d-none');
        },

        // Toggle menu for autocomplete
        toggleMenu(ref) {
            if (!this.$refs[ref].$children[1].$el.classList.contains('d-none')) {
                this.$refs[ref].$children[1].$el.classList.add('d-none');
            } else {
                this.$refs[ref].$children[1].$el.classList.remove('d-none');
            }
        },

        isNumber(e, maxLength = null, checkDot = false) {
            e = (e) ? e : window.event;

            let charCode = (e.which) ? e.which : e.keyCode;
            let length = parseInt(e.target.value.length);
            let dotCount = 0;

            if (checkDot) {
                dotCount = e.target.value.split(".").length - 1;
                length = length - dotCount;
            }

            if ((charCode > 31 && (charCode < 48 || charCode > 57))
                && charCode !== 46 && charCode !== 43) {
                e.preventDefault();
            }

            if (maxLength && length >= parseInt(maxLength)) {
                e.preventDefault();
            }

            return true;
        },
    },
})

axios.get(window.Laravel.language_url).then(res => {
    if (res.data !== '') {
        init(res.data);
    } else {
        init('nl_NL');
    }
}).catch(() => {
    init('nl_NL');
});

const store = new Vuex.Store({
    state: {
        count: 0
    },

    getters: {

    },

    mutations: {

    }
})
