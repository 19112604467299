<script>
    import {DataTable} from 'laravel-vue-datatable';
    import ActionDataTableButtons from "./ActionDataTableButtons";
    import _ from "lodash";

    export default {
        name: 'systems-table',

        props: [
            'systemsEndpoint',
            'allSystemsEndpoint',
            'subSystemsEndpoint',
            'updateSystemEndpoint',
            'tubesEndpoint',
            'storeSystemEndpoint',
            'storeSubSystemEndpoint',
            'destroySystemEndpoint'
        ],

        components: { DataTable, ActionDataTableButtons },

        data() {
            return {
                systems: [],
                subSystems: [],
                system: {
                    display_name: '',
                },
                subSystem: {
                    system_id: '',
                    name: '',
                    tube_diameters: '',
                    type: '',
                    cooling: '',
                    insulation: '',
                    insulation_thickness: '',
                    tubes: [],
                    tube_specification: ''
                },
                columnsSystems: [
                    {
                        name: "name_formatted",
                        columnName: "display_name",
                        label: this.$gettext('Name')
                    },
                    {
                        name: "count_sub_systems",
                        label: this.$gettext('Sub systems count')
                    },
                    {
                        meta: {0: 'system'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                columnsSubSystems: [
                    {
                        name: "name_formatted",
                        columnName: "display_name",
                        label: this.$gettext('Name')
                    },
                    {
                        name: "system_name_formatted",
                        columnName: "system_name",
                        label: this.$gettext('Main system')
                    },
                    {
                        name: "type_formatted",
                        columnName: 'type',
                        label: this.$gettext('Type'),
                        orderable: true
                    },
                    {
                        name: "cooling_formatted",
                        columnName: "cooling",
                        label: this.$gettext('Cooling'),
                        orderable: true
                    },
                    {
                        name: "insulation_formatted",
                        columnName: "insulation",
                        label: this.$gettext('Insulation'),
                        orderable: true
                    },
                    {
                        name: "tube_formatted_table",
                        label: this.$gettext('Tubes')
                    },
                    {
                        name: "diameters_formatted_table",
                        label: this.$gettext('Tube diameters')
                    },
                    {
                        name: "tube_specification_formatted",
                        columnName: "tube_specification",
                        label: this.$gettext('Tube specification'),
                        orderable: true
                    },
                    {
                        meta: {0: 'sub_system'},
                        label: this.$gettext('Actions'),
                        component: ActionDataTableButtons,
                        event: 'click',
                        handler: this.getRowData
                    },
                ],
                perPage: ['10', '25', '50', '100'],
                tableProps: {
                    search: '',
                    length: 10,
                    column: 'id',
                    dir: 'asc',
                },
                translate: {
                    nextButton: this.$gettext('Next'),
                    previousButton: this.$gettext('Previous'),
                    placeholderSearch: this.$gettext('Search systems'),
                },
                editData: {},
                rowData: {},
                errors: [],
                tubes: [],
                diametersAllowed: [],
            }
        },

        mounted() {
            this.getSystems();
            this.getTubes();
        },

        methods: {
            getSystems() {
                axios.get(this.allSystemsEndpoint).then(response => {
                    this.systems = response.data;
                })
            },

            getSubSystems() {
                axios.get(this.subSystemsEndpoint).then(response => {
                    this.subSystems = response.data;
                })
            },

            getTubes() {
                axios.get(this.tubesEndpoint).then(response => {
                    this.tubes = response.data;
                })
            },

            resetModal() {
                let self = this;

                this.errors = [];

                this.subSystem = {
                    system_id: '',
                    name: '',
                    tube_diameters: '',
                    type: '',
                    cooling: '',
                    insulation: '',
                    insulation_thickness: '',
                    tubes: this.tubes.map(function(item) { return item["name"] }),
                    tube_specification: ''
                }

                this.resetTubeDiameters(true)
            },

            resetTubeDiameters(newSystem = false, initial = false) {
                this.diametersAllowed = [];

                if (newSystem) {
                    this.subSystem.tube_diameters = '';

                    let checkedTubes = this.tubes.filter(el => this.subSystem.tubes.includes(el.name));

                    checkedTubes.forEach(tube => {
                        this.diametersAllowed.push(Object.values(JSON.parse(tube.diameters)))
                    })

                    this.diametersAllowed = [...new Set(this.diametersAllowed.flat())].sort(function(a, b) {
                        return a - b;
                    });
                } else {
                    if (initial) this.editData.tube_diameters = '';

                    let checkedTubes = this.tubes.filter(el => this.editData.tubes.includes(el.name));

                    checkedTubes.forEach(tube => {
                        this.diametersAllowed.push(Object.values(JSON.parse(tube.diameters)))
                    })

                    this.diametersAllowed = [...new Set(this.diametersAllowed.flat())].sort(function(a, b) {
                        return a - b;
                    });
                }
            },

            addSystem() {
                this.errors = [];

                axios.post(this.storeSystemEndpoint, {
                    'data': this.system
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addSystem").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            addSubSystem() {
                this.errors = [];

                axios.post(this.storeSubSystemEndpoint, {
                    'data': this.subSystem
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#addSubSystem").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            editItem() {
                this.errors = [];

                axios.post(this.updateSystemEndpoint, {
                    'data': this.editData
                }).then(response => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else {
                        if (response.status == 200) {
                            $("#itemModalEdit").modal('hide');

                            window.location.reload();
                        }
                    }
                })
            },

            deleteItem(id, type) {
                axios.post(this.destroySystemEndpoint, {
                    id, type
                }).then(response => {
                    if (response.status == 200) {
                        $("#itemModalDelete").modal('hide');

                        window.location.reload();
                    }
                })
            },

            getRowData(actionName, data, meta) {
                this.rowData = Object.assign({}, data);
                this.rowData.type = meta[0];
                this.rowData.system_type = data.type;

                this.editData = {
                    'id': data.id,
                    'name': data.name,
                    'system_id': data.system_id,
                    'display_name': data.display_name,
                    'count_sub_systems': data.count_sub_systems ?? '',
                    'cooling': data.cooling,
                    'insulation': data.hasOwnProperty('insulation') ? data.insulation.toString() : '',
                    'insulation_thickness': data.insulation_options !== 'No' && data.insulation_options !== '' ? data.insulation_options : '',
                    'tubes': data.tubes ? data.tubes.map(function(item) { return item["name"] }) : '',
                    'tube_diameters': Object.values(JSON.parse(data.diameters)) ?? '',
                    'tube_specification': data.tube_specification ?? '',
                    'system_type': data.type ?? '',
                    'type': meta[0],
                }

                this.resetTubeDiameters(false, false)
            },
        }
    }
</script>
